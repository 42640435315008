import React, {memo, useEffect, useState} from 'react';
import PropTypes from 'prop-types';
import classes from "../../containers/Accommodations/Accommodations.module.css";
import Inmate from "./Inmate/Inmate";
import Building from "./Building/Building";
import TimeControls from "../UI/TimeControls/TimeControls";
import {addToAndFrom} from "../AccTable/AccTable";
import BuildingReport from "../BuildingReport/BuildingReport";

AccGraphic.propTypes = {
    accommodations: PropTypes.array,
};

function AccGraphic({accommodations, current, monthChangeHandler, buildings, userType}) {
    const [dragged, setDragged] = useState(null);
    const [selectedDay, setDay] = useState(new Date().getDate());
    const [currentBuilding, setBuilding] = useState(buildings.building1);
    const [homeless, setHomeless] = useState(false);
    const [show, setShow] = useState(false);

    return (
        <React.Fragment>
            <TimeControls currentMonth={current.month}
                          currentYear={current.year}
                          changeMonth={monthChangeHandler}/>
            <div className={classes.HiddenDiv}>

                {[...Array(new Date(current.year, current.month + 1, 0)
                    .getDate()).keys()].map(day => <span
                    onClick={() => setDay(day + 1)} key={day}
                    className={new Date(current.year, current.month, day - 1)
                        .getDay() >= 5 ? classes.Weekends : classes.Date}>
                    {day + 1 === selectedDay ?
                    <span style={{color: '#F8C626'}}>{day + 1}</span> : day + 1}
            </span>)}

                <h2 style={{position: "relative"}}>
                <span
                    onClick={() => setBuilding(buildings[`building${
                        currentBuilding[2][0] > 1 ? currentBuilding[2][0] - 1 : currentBuilding[2][0]}`])
                    }
                    className={classes.BuildingArrows}
                    style={{marginLeft: "-50px"}}
                >&#x21C7;</span>
                    <span onClick={() => setShow(true)} className={classes.BuildingName}>
                        Корпус {currentBuilding[2]}</span>
                    <span className={classes.BuildingArrows}
                          style={{marginLeft: "20px"}}
                          onClick={() => setBuilding(buildings[`building${
                              currentBuilding[2][0] < 3 ? currentBuilding[2][0] + 1 : currentBuilding[2][0]}`])
                          }
                    >&#x21C9;</span>
                </h2>
                <Building acc={accommodations}
                          building={currentBuilding}
                          selectedDay={selectedDay}
                          dragged={dragged}
                          current={current}/>
            </div>
            <div className={classes.HiddenDiv} style={{margin: "5px 8%"}}>
                <h3 onClick={() => ["admin", "adAbs"].includes(userType) && setHomeless(!homeless)}
                    className={classes.Homeless}>{homeless ? "Нерасселенные:": "<==========>"}</h3>
                {homeless && addToAndFrom(accommodations, current)
                    .filter(acc => !(acc.rooms && acc.rooms[`year${current.year}`]
                        && acc.rooms && acc.rooms[`year${current.year}`][current.month] &&
                        acc.rooms[`year${current.year}`][current.month]?.[0]?.room !== "Нет"))
                    .sort((acc1, acc2) => {
                        let comparison = 0;
                        if (acc1.name > acc2.name) {
                            comparison = 1;
                        } else if (acc1.name < acc2.name) {
                            comparison = -1;
                        }
                        return comparison;
                    })
                    .map(acc => <Inmate
                        key={acc._id}
                        acc={acc}
                        setDragged={setDragged}
                    />)}
            </div>
            <BuildingReport  currentBuilding={currentBuilding}
                             acc={accommodations}
                             current={current}
                             show={show}
                             setShow = {setShow}
                             selectDay={selectedDay}
            />
        </React.Fragment>
    );
}

export default memo(AccGraphic);