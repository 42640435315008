import React from 'react';
import classes from './Button.module.css'

function Button(props) {
    return (
        <button style={props.style}
            className= {props.disabled ? classes.ButtonDisabled : classes.Button}
            disabled={props.disabled}
            onClick={props.clicked}>
            {props.children}
        </button>
    );
}

export default Button;
