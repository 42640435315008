import React, {useState, useEffect} from 'react';
import TimeControls from "../../components/UI/TimeControls/TimeControls";
import Spinner from "../../components/UI/Spinner/Spinner";
import Order from "../../components/Order/Order";
import {getTravels, removeTravel, statusChanger} from "../../reducers/actions/travelsActions";
import {connect} from "react-redux";


const Orders = ({orders, ordersLoading, userType, getTravels, statusChanger, removeTravel}) => {
    const [current, setCurrent] = useState({
        month: new Date().getMonth(),
        year: new Date().getFullYear()
    });

    const monthChangeHandler = (month) => {
        if (month === -1) {
            month = 11
            setCurrent(prevState => ({...prevState, year: prevState.year - 1}));
        }
        if (month === 12) {
            month = 0
            setCurrent(prevState => ({...prevState, year: prevState.year + 1}));
        }
        setCurrent(prevState => ({...prevState, month: month}));
    };

    useEffect(() => getTravels(current.year, current.month + 1), [current])

    return (
        <div>
            <TimeControls currentMonth={current.month}
                          currentYear={current.year}
                          changeMonth={monthChangeHandler}/>
            <React.Fragment>
                {ordersLoading ? <Spinner/> :
                    orders
                        //by the request denied oreders are hidden from adFulfill
                        .filter(order => !(userType === "adFulfill" && order.status === "denied"))
                        .map(order => <Order data={order}
                                               userType={userType}
                                               key={order._id}
                                               removeTravel={removeTravel}
                                               statusChanger={statusChanger}/>)}
            </React.Fragment>
        </div>
    );
}
export default connect(state => ({
    userType: state.auth.user.type,
    orders: state.travels.orders,
    ordersLoading: state.travels.ordersLoading
}), {getTravels, statusChanger, removeTravel})(Orders);