import _ from "lodash";
import {
    ADD_EMPLOYEE, APPROVE_TIMESHEET, DELETE_EMPLOYEE,
    GET_EMPLOYEES, HANDLE_OPTION, SAVE_TIMESHEET, SET_LOADING
} from './actionTypes';

const initialState = {
    loading: true
};

function timeSheetreducer(state = initialState, action) {
    const {type, payload, workhours} = action;
    switch (type) {
        case SET_LOADING:
            return {
                ...state,
                loading: true
            }
        case GET_EMPLOYEES:
            return {
                ...state,
                loading: false,
                employees: payload,
                workhours: workhours
            };
        case ADD_EMPLOYEE:
            return {
                ...state,
                employees: [...state.employees, payload]
            }
        case DELETE_EMPLOYEE:
            return {
                ...state,
                employees: state.employees.filter(item => item._id !== payload)
            }
        case HANDLE_OPTION:
            const changed = _.cloneDeep(state.employees);
            changed.filter(emp => emp._id === payload.coord.id)[0].timesheet
                .filter(s => s.year == payload.coord.year)[0]
                .sheet[payload.coord.month][payload.coord.day] = payload.option;
            return {
                ...state,
                employees: [...changed]
            }
        case SAVE_TIMESHEET:
            const changed2 = _.cloneDeep(state.employees);
            payload.forEach(el => {
                changed2.filter(emp => emp._id === el.id)[0].timesheet = el.timesheet;
            })
            return {
                ...state,
                loading: false,
                employees: changed2
            }
        case APPROVE_TIMESHEET:
            const changed3 = _.cloneDeep(state.employees);
            changed3.filter(emp => emp._id === payload._id)[0].timesheet = payload.timesheet;
            changed3.filter(emp => emp._id === payload._id)[0].approved = true;
            return {
                ...state,
                loading: false,
                employees: changed3
            }
        default:
            return state;
    }
}

export default timeSheetreducer;