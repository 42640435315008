import React, {useEffect, useState} from 'react';
import {Styles} from "../StylesForTransport";
import {Table} from "../Transport";
import axios from "axios";
import {allDepartments} from "../../../utils/allDepartments";

function KhTransport({current, firstDayOfTheWeek, columns, acc, lastDayOfTheWeek, vehicleUpdate, userType}) {
    const [accommodations, setAcc] = useState(acc);
    const [vehicle, setVehicle] = useState(false);
    const [editWeek, setEditWeek] = useState([]);

    useEffect(() => {
        if (firstDayOfTheWeek(current.week).getFullYear()
            < lastDayOfTheWeek(current.week).getFullYear()) {
            //todo maybe setLoading??
            (async () => {
                const {data} = await axios.get(`/api/accommodation/${firstDayOfTheWeek(current.week)
                    .getFullYear()}`);
                setAcc([...accommodations, ...data]);
            })();
        } else {setAcc([...acc])}
    }, [acc, current.week])

    const weekCoords = [];
    for (let i = -1; i <= 7; i++) {
        const theDay = new Date(
            firstDayOfTheWeek(current.week).getFullYear(),
            firstDayOfTheWeek(current.week).getMonth(),
            firstDayOfTheWeek(current.week).getDate() + i,
        )
        weekCoords.push([
            theDay.getFullYear(), theDay.getMonth(), theDay.getDate() - 1
        ]);
    }

    const coming = accommodations.map(p => ({
        ...p, timesheets: weekCoords.map((d, ind, arr) =>
                (ind > 0 && ind < 7) &&
                p.timesheets?.[`year${arr[ind][0]}`]?.[arr[ind][1]]?.[arr[ind][2]] === "ПУ"
                && (
                    !["11", "10"].includes(p.timesheets?.[`year${arr[ind - 1][0]}`]?.[arr[ind - 1][1]]?.[arr[ind - 1][2]])
                    || ["11", "10"].includes(p.timesheets?.[`year${arr[ind + 1][0]}`]?.[arr[ind + 1][1]]?.[arr[ind + 1][2]])
                )
                && (allDepartments.includes(p.department) ||
                    p.vehicle?.[`year${firstDayOfTheWeek(current.week).getFullYear()}`]?.[current.week]?.length)
                && new Date(d[0], d[1], d[2] + 1, 3).toLocaleDateString('ru-RU')
        ).filter(n => n).join(" ")
    })).filter(p => p.timesheets);

    const going = accommodations.map(p => ({
        ...p, timesheets: weekCoords.map((d, ind, arr) =>
            (ind > 0 && ind < 7) && p.timesheets?.[`year${arr[ind][0]}`]?.[arr[ind][1]]?.[arr[ind][2]] === "ПУ" &&
            (
                ["11", "10"].includes(p.timesheets?.[`year${arr[ind - 1][0]}`]?.[arr[ind - 1][1]]?.[arr[ind - 1][2]])
                || !["11", "10"].includes(p.timesheets?.[`year${arr[ind + 1][0]}`]?.[arr[ind + 1][1]]?.[arr[ind + 1][2]])
            )
            && (allDepartments.includes(p.department) ||
                p.vehicle?.[`year${firstDayOfTheWeek(current.week).getFullYear()}`]?.[current.week]?.length)
            && new Date(d[0], d[1], d[2] + 1, 3).toLocaleDateString('ru-RU')
        ).filter(n => n).join(" ")
    })).filter(p => p.timesheets);

    useEffect(()=> {
        const edited = accommodations.find(o=> o._id === vehicle);
        setEditWeek(edited?.vehicle?.[`year${firstDayOfTheWeek(current.week).getFullYear()}`]?.[current.week] || [])
    }, [vehicle]);
    const editForm = (p) => {

        return [<input type="text" value={ editWeek.map(m => m[0]).join(", ")}
                       onChange={e => setEditWeek(eee =>
                           e.target.value.split(",").map((v, j) =>
                               [v.trim(), eee?.[j]?.[1] || "", eee?.[j]?.[2] || ""])
                       )}
        />,
            <input type="text" value={ editWeek.map(m => m[1]).join(", ")}
                   onChange={e => setEditWeek(eee =>
                       e.target.value.split(",").map((v, j) =>
                           [eee?.[j]?.[0] || "", v.trim(), eee?.[j]?.[2] || ""])
                   )}
            />
            , <div style={{whiteSpace: "nowrap"}}>
                <input  type="text" value={ editWeek.map(m => m[2]).join(", ")}
                     onChange={e => setEditWeek(eee =>
                         e.target.value.split(",").map((v, j) =>
                             [eee?.[j]?.[0] || "", eee?.[j]?.[1] || "", v.trim()])
                     )}
            /><div className="SaveDriver" onClick={() => {
                const updated = p.vehicle?.[`year${firstDayOfTheWeek(current.week).getFullYear()}`] || [];
                updated[current.week] = editWeek;
                vehicleUpdate(updated, p._id, current.year);
            }}>💾</div></div>]
    }
    const addVehicle = data => data.map(p => ({
        name: p.name, position: p.position, department: p.department, timesheets: p.timesheets,
        vehicle: <div onClick={() => ["admin", "adAbs","adTrans"].includes(userType) && setVehicle(p._id)}>
            {vehicle === p._id ? editForm(p)[0]
                : p?.vehicle?.[`year${firstDayOfTheWeek(current.week).getFullYear()}`]?.[current.week]?.[0]?.[0] ?
                    p?.vehicle[`year${firstDayOfTheWeek(current.week).getFullYear()}`][current.week].map(d => d[0]).join(",")
                : "Не назначен"}
        </div>,
        driver: <div onClick={() => ["admin", "adAbs","adTrans"].includes(userType) && setVehicle(p._id)}>
            {vehicle === p._id ? editForm(p)[1]
                : p?.vehicle?.[`year${firstDayOfTheWeek(current.week).getFullYear()}`]?.[current.week]?.[0]?.[1] ?
                    p?.vehicle[`year${firstDayOfTheWeek(current.week).getFullYear()}`][current.week].map(d => d[1]).join(",")
                : "Не назначен"}
        </div>,
        time: <div onClick={() => ["admin", "adAbs","adTrans"].includes(userType) && setVehicle(p._id)}>
            {vehicle === p._id ? editForm(p)[2]
                : p?.vehicle?.[`year${firstDayOfTheWeek(current.week).getFullYear()}`]?.[current.week]?.[0]?.[2] ?
                    p?.vehicle[`year${firstDayOfTheWeek(current.week).getFullYear()}`][current.week].map(d => d[2]).join(",")
                : "Не определено"}
        </div>
    }));
    const data1 = addVehicle(coming);
    const data2 =addVehicle(going);
    const columns1 = [{...columns[0], Header: "Из Усинска"}];
    const columns2 = [{...columns[0], Header: "С Харьяги"}];

    return (
        <Styles>
            <Table columns={columns1} data={data1}/>
            <br/>
            <Table columns={columns2} data={data2}/>
        </Styles>
    );
}

export default KhTransport;