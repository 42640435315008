import React from 'react';
import classes from "./Order.module.css";
import Button from "./OrderButton/OrderButton";
import UploadFiles from "./UploadFiles/UploadFiles";
import DownloadFiles from "./DownloadFiles/DownloadFiles";
import axios from "axios";

const Order = props => {
    const makeOutdated = async () => {
        const id = props.data._id;
        const config = {
            headers: {
                'Content-Type': 'application/json',
            },
        };
        await axios.put(`/api/travels/outdated/${id}`, null, config);
        window.alert("Заявка отменена, обновите страницу чтобы увидеть изменения");
    }
    const rowItenerary = it =>
        <React.Fragment>
            {!(["adFulfill"].includes(props.userType) && it.type === "Авто") && <tr>
                <td>
                    {it.departure}
                </td>
                <td>
                    {it.arrival}
                </td>
                <td>
                    {it.date}
                </td>
                <td>
                    {it.type}
                </td>
                <td>
                    {it.flight}
                </td>
                <td>
                    {it.timeD}
                </td>
                <td>
                    {it.timeA}
                </td>
            </tr>}
            {it.notes && <tr>
                <td colSpan="7">
                    {it.notes}
                </td>
            </tr>}
        </React.Fragment>
    const options = {
        year: 'numeric',
        month: 'numeric',
        day: 'numeric',
    };
    return (
        <div className={classes.Card}>
            {props.data.outdated && <div className={classes.Canceled}> ОТМЕНЕН </div>}
            <div><b>{new Date(props.data.day).toLocaleString("ru", options)}: </b>
                Заявка на билеты для {props.data.empId.name} ({props.data.empId.position})
                из: {props.data.empId.department} Внутренний № {props.data._id}

                <p>
                    Дата рождения: {props.data.empId.DOB},
                    Гражданство: {props.data.empId.citizenship},
                    Номер и серия паспорта: {props.data.empId.passport},
                    email: {props.data.empId.email},
                    Телефон: {props.data.empId.phone},
                    Бонусная карта: {props.data.empId.bonus},
                </p>
                {props.data.itineraries[0].departure && <table className={classes.ItineraryTable}>
                    <tr>
                        <th>
                            Пункт отправления
                        </th>
                        <th>
                            Пункт назначения
                        </th>
                        <th>
                            Дата
                        </th>
                        <th>
                            Вид
                        </th>
                        <th>
                            Рейс/Поезд
                        </th>
                        <th>
                            Отправление
                        </th>
                        <th>
                            Прибытие
                        </th>
                    </tr>
                    {rowItenerary(props.data.itineraries[0])}
                    {props.data.itineraries[1].departure && rowItenerary(props.data.itineraries[1])}
                    {props.data.itineraries[2].departure && rowItenerary(props.data.itineraries[2])}
                    {props.data.itineraries[3]?.departure && rowItenerary(props.data.itineraries[3])}
                </table>}
                {props.data.hotel.name &&
                <p><b>Гостиница</b> {props.data.hotel.name}. Город {props.data.hotel.city}.
                    Заезд {props.data.hotel.checkIn} в {props.data.hotel.checkInT}
                    . Выезд {props.data.hotel.checkOut} в {props.data.hotel.checkOutT}
                    {props.data.hotel.notes && <em> Примечание: {props.data.hotel.notes}</em>}
                </p>}
            </div>
            {
                ["admin", "adApprove", "adFulfill"].includes(props.userType) ? <div>
                    {props.data.status === "updated" ? ["admin", "adApprove"].includes(props.userType) ?
                        [<Button key={"a" + props.data._id} btnType="Approve"
                                 disabled={!["admin", "adApprove", "adFulfill"].includes(props.userType)}
                                 clicked={() => props.statusChanger(props.data._id, "approved")}>Согласовано</Button>,
                            <Button key={"b" + props.data._id} btnType="Reject"
                                    disabled={!["admin", "adApprove"].includes(props.userType)}
                                    clicked={() => props.statusChanger(props.data._id, "denied")}>Не
                                согласовано</Button>] :
                        <p style={{color: "green"}}>ёще на рассмотрении</p> : null}

                    {props.data.status === "denied" && <Button btnType="Reject" disabled>Не согласовано</Button>}

                    {props.data.status === "approved" &&
                    [<Button btnType="Fulfilled" disabled={!["admin", "adFulfill"].includes(props.userType)}
                            clicked={() => props.statusChanger(props.data._id, "fulfilled")}>Выполнено</Button>,
                    <Button key={"b" + props.data._id} btnType="Reject"
                            disabled={!["admin", "adFulfill"].includes(props.userType)}
                            clicked={() => props.statusChanger(props.data._id, "denied")}>Не выполнять</Button>]
                    }
                    {props.data.status === "fulfilled" && <Button btnType="Fulfilled" disabled={true}
                                                                  clicked={() => null}>Выполнено</Button>
                    }
                </div> : <h4>{props.data.status === "updated" ? "НА РАССМОТРЕНИИ" :
                    props.data.status === "approved" ? "СОГЛАСОВАНО" :
                        props.data.status === "fulfilled" ? "ВЫПОЛНЕНА" : "ОТКЛОНЕНА"}</h4>
            }
            {props.data.uploads[0] ? <DownloadFiles travel_id={props.data._id}
                                                    userType={props.userType}
                                                    links={props.data.uploads}/>
                : ((["adFulfill"].includes(props.userType) && props.data.status === "approved") ||
                    ["admin"].includes(props.userType)) ?
                    <UploadFiles travel_id={props.data._id}/> : null}
            { //add logic for adcitu to delete denied order
                (props.userType === "admin" || (props.userType === "adCitu" && (props.data.status === "denied" ||
                props.data.outdated === true)
                )) &&
            <div className={classes.Delete} onClick={() => props.removeTravel(props.data._id)}>X</div>
            }
            {//logic to make the order outdated
                (props.userType === "admin" || (["adFulfill"].includes(props.userType)
                    && (props.data.status === "fulfilled" ||
                props.data.outdated === false)))
                    && <div className={classes.Outdated} onClick={() => makeOutdated()}>🕰</div>
            }
        </div>)
}

export default Order;