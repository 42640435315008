import React from 'react';
import {getFile, removeFile} from "../../../reducers/actions/travelsActions";
import classes from "./DownloadFiles.module.css";
import {connect} from "react-redux";

const DownloadFiles = ({links, getFile, removeFile, userType, up}) => {
    return (
        <div className={classes.DownloadDiv} style={up && {top: "20px"}}>
            {links.map((link, index) =>
                <div key={index}>
                    <a className={classes.FileLinks}
                        onClick={() => getFile(link)}>Билет {index + 1}
                    </a>
                    {
                        ["admin", "adFulfill"].includes(userType) &&
                    <a className={classes.RemoveCross}
                       onClick={() => removeFile(link)}>❌</a>
                    }
                </div>)
            }
        </div>
    )
}

export default connect(null, {getFile, removeFile})(DownloadFiles);