module.exports = function (month, day) {
    //day starts with 0, month starts with 0
    const year = [
        [1,2,3,4,5,7,8],
        [23],
        [8],
        [],
        [1,9],
        [12],
        [],
        [],
        [],
        [],
        [4],
        []
    ];
    return year[month].includes(day);


}