import React, {useEffect, useState} from 'react';
import RegisterUser from "../../components/Settings/RegisterUser/RegisterUser";
import {connect} from "react-redux";
import {Redirect} from "react-router-dom";
import RemoveEmployee from "../../components/Settings/DeleteEmployee/RemoveEmployee";
import UploadExls from "../../components/Settings/UploadExls/UploadExls";
import Buildings from "../../components/Settings/Buildings/Buildings";


const Settings = ({userType}) => {
    return <div>
        {userType !== "admin" && <Redirect to='/auth'/>}
        <h3 style={{marginTop: "70px"}}>Зарегистрировать нового пользователя</h3>
        <RegisterUser />
        <h3 style={{color: "red"}}>Удалить все даные о работнике</h3>
        <RemoveEmployee />
        <h3 style={{color: "green"}}>Update buildings</h3>
        <Buildings />
        <h3 style={{color: "blue"}}>Upload Excel</h3>
        <UploadExls />
    </div>
}


export default connect(state => ({
    userType: state.auth.user.type,
}))(Settings)