import React from 'react';
import {useForm} from "react-hook-form";
import {connect} from 'react-redux';
import PropTypes from 'prop-types';
import {getTimeSheets, deleteEmployee} from "../../../reducers/actions/timeSheetActions";


const RemoveEmployee = ({deleteEmployee, employees, getTimeSheets}) => {
    if (!employees) {getTimeSheets()}
    const { register, handleSubmit, formState: { errors } } = useForm();
    const onSubmit = (data) => deleteEmployee(data.emp_id);
    return (
        <form onSubmit={handleSubmit(onSubmit)}>
            <label htmlFor="emp_id">Выбрать работника</label>
            <select {...register("emp_id", {required: true})}>
                {employees && employees.map(emp => <option value={emp._id} key={emp._id}>{emp.name}</option>)}
            </select> <br/>
            {errors.emp_id && <p>Нужно кого-то выбрать</p>}<br/>
            <input type="submit" value="Удалить все данные" />
        </form>
    );
}

RemoveEmployee.propTypes = {
    deleteEmployee: PropTypes.func.isRequired,
};

export default connect(state => ({
    employees: state.timeSheets.employees
}), {deleteEmployee, getTimeSheets})(RemoveEmployee);