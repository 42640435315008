import React, {Fragment, useEffect, useState} from 'react';
import {useForm} from "react-hook-form";
import {registerNewUser} from "../../../reducers/actions/authActions";
import {connect} from 'react-redux';
import PropTypes from 'prop-types';
import axios from "axios";
import {confirm} from "react-confirm-box";

//rmarkin@nestro.ru pass: rmarkinRmarkiNN
function RegisterUser({registerNewUser}) {
    const {register, handleSubmit, formState: {errors}} = useForm();
    const onSubmit = data => registerNewUser(data);
    const [users, setUsers] = useState([]);
    useEffect(() => {
        (async () => {
            const res = await axios.get('/api/users/all');
            setUsers(res.data?.users)
        })()
    }, [])
    const deleteUser = async (id) => {
        if (window.confirm("User with ID " + id + " will be removed") ) {
            await axios.delete(`api/users/${id}`);
            window.alert("Reload this page")
        }
    }
    return <Fragment>
        <h2 style={{color: 'green'}}>Users:</h2>
        <table style={{margin: '0 auto'}}>
            <tbody>
            {users.length ? users.map(u => <tr key={u._id}>
                <td style={{border: 'solid 2px green'}}>
                    {u.date}</td>
                <td style={{border: 'solid 2px green'}}>
                    {u.email}</td>
                <td style={{border: 'solid 2px green'}}>
                    {u.name}</td>
                <td style={{border: 'solid 2px green'}}>
                    {u.type}</td>
                <td style={{border: 'solid 2px green'}}
                onClick={() => deleteUser(u._id)}>
                    X </td>
            </tr>) : <tr>
                <td colSpan={4}>Loading...</td>
            </tr>}
            </tbody>
        </table>
        <br/>
        <form onSubmit={handleSubmit(onSubmit)}>
            <label htmlFor="name">Имя пользователя:</label>
            <input {...register("name", {required: true, maxLength: 30, minLength: 6})} />
            {errors.name && <p>Не менее 12 не более 30 символов</p>} <br/>
            <label htmlFor="password">Пароль (запиши!):</label>
            <input {...register("password", {required: true, minLength: 12, maxLength: 20})} />
            {errors.password && <p>Не менее 12 не более 20 символов</p>}<br/>
            <label htmlFor="email">Email:</label>
            <input type="email" {...register("email", {required: true, minLength: 10})} />
            {errors.email && <p>Не менее 10 символов</p>}<br/>
            <label htmlFor="type">Роль в системе</label>
            <select {...register("type", {required: true})}>
                <option value="adCitu">Администратор ЦИТУ</option>
                <option value="adApprove">Начальнник ЦИТУ</option>
                <option value="adFulfill">Агент покупки билетов</option>
                <option value="pbotos">Начальник ОТ, ПБ и ООС</option>
                <option value="cdng">Начальник ЦДНГ</option>
                <option value="cppng">Начальник ЦППНиГ</option>
                <option value="uoire">Начальник УОиРЭ</option>
                <option value="utoir">Начальник УТОиРО</option>
                <option value="ihal">Начальник ИХАЛ</option>
                <option value="tu">Начальник ТУ</option>
                <option value="uchso">Начальник УчСО</option>
                <option value="sb">Начальник Службы безопасности</option>
                <option value="sbrs">Начальник Бурения и КРС</option>
                <option value="oom">Начальник ООМ</option>
                <option value="okk">Начальник ОКК</option>
                <option value="adAbs">Начальнник АБС</option>
                <option value="adTrans">Транспорт и трансферы</option>
            </select> <br/>
            <input type="submit" value="Зарегистрировать"/>
        </form>
    </Fragment>
}

RegisterUser.propTypes = {
    registerNewUser: PropTypes.func.isRequired,
};
export default connect(null, {registerNewUser})(RegisterUser);