import React, {useEffect, useState} from 'react';
import classes from "./Building.module.css";
import {connect} from "react-redux";
import {roomsUpdate} from "../../../reducers/actions/accActions";
import {convertDate} from "../../AccTable/EditRoom/EditRoom";
import BuildingReport from "../../BuildingReport/BuildingReport";


const Room = connect(null, {roomsUpdate})((
    {
        room,
        bNumber, takenInCurrentMonth,
        roomsUpdate,
        dragged, current, selectedDay
    }) => {
    const [dragOver, setDragOver] = useState(false);
    const [capacity, setCapasity] = useState(2);
    const [information, setInformation] = useState(null)
    const onDrop = e => {
        setDragOver(false)
        const newRooms = (dragged.rooms && dragged.rooms[`year${current.year}`]) ?
            dragged.rooms[`year${current.year}`] : [];
        newRooms[current.month] = [{
            room: bNumber + "-" + room,
            from: dragged.from.split(",")[0] === "=>" ?
                new Date(current.year, current.month, 1, 3).toISOString().split('T')[0]
                : convertDate(dragged.from.split(",")[0]),
            to: dragged.to.split(",")[dragged.to.split(",").length - 1] === "=>" ?
                new Date(current.year, current.month + 1, 0, 3).toISOString().split('T')[0]
                : convertDate(dragged.to.split(",")[dragged.to.split(",").length - 1]),
        }]
        roomsUpdate(newRooms, dragged._id, current.year);
    }
    const whoIsHereToday = takenInCurrentMonth?.filter(acc => acc?.rooms?.[`year${current.year}`]?.[current.month]
        .some(r => {
                const fromDay = r.from === "=>" ? 1 : new Date(r.from).getDate()
                const toDay = r.to === "=>" ?
                    new Date(current.year, current.month + 1, 0).getDate()
                    : new Date(r.to).getDate();
                return r.room === bNumber + "-" + room &&
                    selectedDay >= fromDay
                    && selectedDay <= toDay;
            }
        ));
    useEffect(() => {
        setCapasity(parseInt(room.split("(")[1]) > 1 ?
            parseInt(room.split("(")[1]) - whoIsHereToday.length :
            2 * parseInt(room.split("(")[1]) - 2 * whoIsHereToday.length);
    }, [selectedDay, current, takenInCurrentMonth]);
    return <div className={
        room === "___(0)" ? [classes.Room, classes.NotForGuests].join(" ") :
            dragOver ? classes.Room + " " + classes.RoomDragOver :
                capacity < 0 ? [classes.Room, classes.RoomOv].join(" ") :
                    capacity === 0 ? [classes.Room, classes.RoomF].join(" ") :
                        capacity === 1 ? [classes.Room, classes.RoomHF].join(" ") :
                            classes.Room}
                onDragOver={ev => {
                    setDragOver(true)
                    ev.preventDefault()
                }}
                onDragLeave={() => setDragOver(false)}
                onDrop={e => onDrop(e)}
                onMouseLeave={() => setInformation(null)}
                onDoubleClick={() => setInformation(whoIsHereToday?.length && whoIsHereToday.map(i => (
                    {name: i.name, department: i.department, comment: i.comment})))}
    >{room === '___(0)' ? '_' : room}
        {information && <div className={classes.OnDoubleClick}>{information
            .map(o => `${o.name} из ${o.department} (${o.comment || ""})`).join(" и ")
        }</div>}
    </div>;
});

function Building({building, dragged, current, selectedDay, acc}) {
    const takenInCurrentMonth = acc.filter(a =>
        a.rooms && a.rooms[`year${current.year}`] &&
        a.rooms[`year${current.year}`][current.month] &&
        a.rooms[`year${current.year}`][current.month]
            .filter(r => r.room[0] === building[2]));
    const occupied = takenInCurrentMonth.filter(a => a.rooms[`year${current.year}`][current.month]
        .some(r => {
            const fromDay = r.from === "=>" ? 1 : new Date(r.from).getDate()
            const toDay = r.to === "=>" ?
                new Date(current.year, current.month + 1, 0).getDate()
                : new Date(r.to).getDate();
            return r.room[0] === building[2].toString() &&
                selectedDay >= fromDay
                && selectedDay <= toDay;
        })).length;
    const vacant = building[0].flat(2).map(r => parseInt(r[4])).reduce((p, c) => p + c, 0) +
        building[1].flat(2).map(r => parseInt(r[4])).reduce((p, c) => p + c, 0) - occupied;
    return (
        <div className={classes.Building} key={building[2][0]}>
            <div className={classes.Floor}>
                {building[0][0].map((room, ind) => <Room selectedDay={selectedDay}
                                                         takenInCurrentMonth={takenInCurrentMonth}
                                                         key={"01" + ind} room={room} bNumber={building[2]}
                                                         current={current} dragged={dragged}/>)}
                <div className={classes.Corridor}>
                    <div>Первый этаж</div>
                </div>
                {building[0][1].map((room, ind) => <Room selectedDay={selectedDay}
                                                         takenInCurrentMonth={takenInCurrentMonth}
                                                         key={"02" + ind} room={room} bNumber={building[2]}
                                                         current={current} dragged={dragged}/>)}
            </div>
            <div style={{height: "35px"}}><strong>Свободно: <span style={{color: "green"}}>
                {vacant}</span> мест, занято: <span style={{color: "red"}}>
                {occupied}</span> мест.</strong></div>
            <div className={classes.Floor}>
                {building[1][0].map((room, ind) => <Room selectedDay={selectedDay}
                                                         takenInCurrentMonth={takenInCurrentMonth}
                                                         key={"03" + ind} room={room} bNumber={building[2]}
                                                         current={current} dragged={dragged}/>)}
                <div className={classes.Corridor}>Второй этаж</div>
                {building[1][1].map((room, ind) => <Room selectedDay={selectedDay}
                                                         takenInCurrentMonth={takenInCurrentMonth}
                                                         key={"04" + ind} room={room} bNumber={building[2]}
                                                         current={current} dragged={dragged}/>)}
            </div>
        </div>
    );
}

export default Building;