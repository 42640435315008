import React, {memo, useEffect} from 'react';
import {Styles} from "./StylesForTransport";

function WeekControls({week, year, setCurrent, lastDayOfTheWeek}) {

    const daysInYear = ((year % 4 === 0 && year % 100 > 0)
        || year % 400 === 0) ? 366 : 365;
    const weekArray = [...Array(Math.ceil(daysInYear/7)).keys()];

    useEffect(() => {if (lastDayOfTheWeek(week).getFullYear() !== year) {
        setCurrent(c => ({...c, year: lastDayOfTheWeek(week).getFullYear(),
            week: week>51 ? 0 : 51}))
    }}, [week, year]);

    return (
        <Styles>
        <div className={"ControlArrows"}>
            <a onClick={()=>setCurrent(c => ({...c, week: c.week - 1}))}>⮜</a>
            <select
                value={week}
                onChange={(event => {setCurrent(c => ({...c, week: event.target.value*1}))
                })}
                id="month"
                name="month"
                size="1"
                style={{fontSize: '1.5rem'}}>
                {weekArray.map(w => <option key={w + "week"}
                    value={w} >Неделя {w+1}</option>)}
            </select>
            <a onClick={()=>setCurrent(c => ({...c, week: c.week + 1}))}>⮞</a>
            <b style={{fontWeight: 900}}>   {year}</b>
        </div>
        </Styles>
    );
}

export default memo(WeekControls);