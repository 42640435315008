import React from 'react';
import PropTypes from 'prop-types';
import classes from "./Inmate.module.css";


const Inmate = ({acc, setDragged}) => {
    return (
        <div className={classes.Inmate}
             draggable={true}
             onDragStart={() => setDragged({...acc})}
        >{acc.name.split(' ')[0]}
        <div className={classes.HiddenDiv}>
            <em>{acc.comment}</em><b> {`[${acc.from}][${acc.to}]`}</b> {acc.name} <br/> <em>{acc.department}</em>
        </div>
        </div>
    );
}

Inmate.propTypes = {
    acc: PropTypes.object,
};
export default Inmate;