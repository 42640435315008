import setAuthToken from "../../utils/utils";
import {
    AUTH_ERROR,
    CLEAR_PROFILE,
    LOGIN_FAIL,
    LOGIN_SUCCESS,
    LOGOUT,
    USER_LOADED,

} from "../actionTypes";
import axios from "axios";
import {setErrors} from "./errorsActions";

//load user
export const loadUser = () => async (dispatch) => {
    //sets the header, then we can call api and get the user
    if (localStorage.token) {
        setAuthToken(localStorage.token);
    }
    try {
        const res = await axios.get('/api/auth');
        dispatch({
            type: USER_LOADED,
            payload: res.data,
        });
    } catch (err) {
        dispatch({
            type: AUTH_ERROR,
        });
    }
};
//Login user
export const login = (email, password) => async (dispatch) => {
    const config = {
        headers: {
            'Content-Type': 'application/json',
        },
    };
    const body = JSON.stringify({email, password});
    try {
        const res = await axios.post('/api/auth/', body, config);
        dispatch({
            type: LOGIN_SUCCESS,
            payload: res.data,
        });
        dispatch(loadUser());
    } catch (err) {
        const errors = err.response.data.errors;
        if (errors) {
            const messages = errors.map(error => error.msg)
            dispatch(setErrors(messages));
        }
        dispatch({
            type: LOGIN_FAIL,
        });
    }
};

//Logout
export const logout = () => async (dispatch) => {
    try {
        dispatch({
            type: CLEAR_PROFILE,
        });
        dispatch({
            type: LOGOUT,
        });
    } catch (err) {
        const errors = err.response.data.errors;
        if (errors) {
            const messages = errors.map(error => error.msg)
            dispatch(setErrors(messages));
        }
    }
};

//Register user
export const registerNewUser =
    ({ name, email, password, type }) =>
        async (dispatch) => {
            const config = {
                headers: {
                    'Content-Type': 'application/json',
                },
            };
            const body = JSON.stringify({ name, email, password, type });
            try {
                await axios.post('/api/users/', body, config);
                dispatch(setErrors(['User is added to the database']));
            } catch (err) {
                const errors = err.response.data.errors;
                if (errors) {
                    const messages = errors.map(error => error.msg)
                    dispatch(setErrors(messages));
                }
            }
        };





