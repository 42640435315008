import React, {Fragment} from 'react';
import Logo from '../Logo/Logo';
import NavigationItems from '../NavigationItems/NavigationItems';
import classes from './SideDrawer.module.css';
import Backdrop from '../Backdrop/Backdrop';

const sideDrawer = (props) => {
    //conditionally attach css
    const attachedClasses = [classes.SideDrawer, classes.Close ];
    if (props.open) {attachedClasses[1] = classes.Open;}
    return (
        <Fragment>
        <Backdrop clicked={props.closed} show={props.open}/>
        <div className={attachedClasses.join(" ")} onClick={props.closed}>

            <div className={classes.Logo}>
                <Logo />
            </div>
            <nav>
                <NavigationItems/>
            </nav>
        </div>
        </Fragment>
    )
}

export default sideDrawer;