import React, {useEffect, useState} from "react";
import {connect} from "react-redux";
import classes from "./Timesheet.module.css";
import TimeControls from "../../components/UI/TimeControls/TimeControls";
import TimeTable from '../../components/TimeTable/TimeTable';
import AddEmployee from "../../components/AddEmployee/AddEmployee";
import HandleTS from "../../components/HandleTS/HandleTS";
import Spinner from "../../components/UI/Spinner/Spinner";
import Modal from "../../components/UI/Modal/Modal";
import TravelForm from "../../components/TravelForm/TravelForm";
import {
    addEmployee, addTS, deleteTS, getTimeSheets,
    saveTimesheet
} from "../../reducers/actions/timeSheetActions";
import userDepartment from "../../utils/userDepartment";
import {allDepartments} from "../../utils/allDepartments";
import {closeTravelForm} from "../../reducers/actions/travelsActions";
import {Redirect} from "react-router-dom";
import DownLoadExcel from "../../components/Settings/DownLoadExcel/DownLoadExcel";


const Timesheet = ({
                       employees, loading, addTS,
                       getTimeSheets, userType, addEmployee, deleteTS, saveTimesheet,
                       travelForm, closeTravelForm, showModal
                   }) => {
    const [month, setMonth] = useState(new Date().getMonth());
    const [year, setYear] = useState(new Date().getFullYear());

    useEffect(() => !employees && getTimeSheets(), [employees, getTimeSheets]);


    const availableDepartments = (userDepartment(userType) && [userDepartment(userType)]) || allDepartments;
    const addNewEmployeeHandler = (event) => {
        event.preventDefault();
        const newEmployee = {
            name: event.target[0].value,
            position: event.target[1].value,
            department: event.target[2].value,
            year: year
        }
        addEmployee(newEmployee)
    }
    const [pPosition, setPPosition] = useState(null)
    const monthChangeHandler = (currentMonth) => {
        if (month < currentMonth) {setPPosition("FromRight")}
        else {setPPosition("FromLeft")}
        setTimeout(() => setPPosition(" "), 500);
        if (currentMonth === -1) {
            setMonth(11);
            setYear(year - 1);
        } else if (currentMonth === 12) {
            setMonth(0);
            setYear(year + 1)
        } else setMonth(currentMonth);
    }



    const timeSheetContent = <div className={classes.TimeSheet}>
        <TimeControls currentMonth={month}
                      currentYear={year}
                      changeMonth={monthChangeHandler}
        />
        <TimeTable year={year}
                   collapseDepartments = {["admin", "adApprove", "adCitu"].includes(userType)}
                   currentM={month}
                   daysInMonth={new Date(year, month + 1, 0).getDate()}
                   employees={employees}
                   saveChanges={saveTimesheet}
                   userType={userType}
                   pPosition={pPosition}
        />
        <hr style={{marginTop: "40px"}}/>
        <section>
            {!["adApprove", "adFulfill"].includes(userType) &&
            <HandleTS deleteTS={deleteTS} year={year} addTS={addTS} employees={employees}/>}
            {!["adApprove", "adFulfill"].includes(userType) &&
            <AddEmployee
                availableDepartments={availableDepartments}
                newOne={addNewEmployeeHandler}/>
            }
            {["adCitu", "admin"].includes(userType) && <DownLoadExcel />}
        </section>
    </div>
    return <React.Fragment>
        {["adFulfill"].includes(userType) && <Redirect to="/travels/"/>}
        {loading ? <Spinner/> :
            timeSheetContent
        }
        <Modal show={showModal} passDownToBackdrop={closeTravelForm}>
            {travelForm ? <TravelForm/> : <Spinner/>}
        </Modal>
    </React.Fragment>
}
const mapStateToProps = state => ({
    employees: state.timeSheets.employees,
    loading: state.timeSheets.loading,
    userType: state.auth.user.type,
    travelForm: state.travels.travelForm,
    showModal: state.travels.showModal
})

export default connect(mapStateToProps, {
    getTimeSheets, addEmployee, deleteTS, addTS, saveTimesheet, closeTravelForm
})(Timesheet);