import React, {useEffect, useRef, useState} from 'react';
import classes from "../TimeTable.module.css";
import NameCell from "./NameCell/NameCell";
import TimeCell from "./TimeCell/TimeCell";
import ApproveButton from "../ApproveButton/ApproveButton";
import {approveTimesheet} from "../../../reducers/actions/timeSheetActions";
import { connect } from "react-redux";
import calculateTs from '../../../utils/calculateTS';


const Employee = React.memo(props => {
    const [currentCellValue, setcurrentCellValue] = useState(null);
    const stopDragHandler = () => {
        setcurrentCellValue(null);
    }
    //make everything look nice in the calendar mode
    const refref = useRef(null);
    if (refref?.current?.children[1]?.style && window.innerWidth<800) {
        let offset = new Date(props.year,props.currentM, 1, 3).getDay() - 1;
        if (offset < 0) offset = 6
        refref.current.children[1].style[`margin-left`] = `${offset*40}px`
    }

    const dragHandler = (event) => {
        setcurrentCellValue(event.target.innerHTML);
    }
    const [overWork, setOverWork] = useState()
    useEffect(() => setOverWork(calculateTs(props.employee.timesheet
        .filter(s => s.year.toString() === props.year.toString())[0].sheet, props.workHours, props.year)),
        [props.year])
    const worHoursView = ["11", "11Н", "10", "10Н", "#11", "#11Н", "#10", "#10Н", 10, 11];
    const thisMonth = props.employee.timesheet.filter(s => s.year === props.year.toString())[0].sheet[props.currentM];
    return <div className={classes.OnePerson} key={props.employee.name}
                ref = {refref}
         onMouseLeave={currentCellValue && stopDragHandler}>
        <NameCell>{props.employee.name}</NameCell>
        {thisMonth.map((value, i) => <TimeCell
                currentCellValue = {currentCellValue}
                stopDragHandler = {stopDragHandler}
                dragHandler = {dragHandler}
                coord = {
                    {id: props.employee._id,
                    month: props.currentM, day: i, year: props.year}
                }
                key={props.employee.name + " " + i}
                value={value}
                setOverWork = {setOverWork}
                />
            )}
        <ApproveButton
            onClick={() => props.approveTimesheet(props.employee._id)}
            disabled={!["admin", "adApprove", "adCitu"].includes(props.userType)}
        />
        <div className={classes.OverWork}>{overWork}
            <div className={classes.Hidden}><span>{props.employee.position}: {thisMonth.filter(d => worHoursView.includes(d)).length} на вахте и {
                thisMonth.filter(d => ["ОТ", "#ОТ"].includes(d)).length} дней в отпуске в этом месяце </span>
            </div>
        </div>

    </div>
});


export default connect(state => ({
    userType: state.auth.user.type,
    workHours: state.timeSheets.workhours
}), { approveTimesheet })(Employee)