export const SET_LOADING = 'SET_LOADING';
export const SET_TRAVELFORM_LOADING = 'SET_TRAVELFORM_LOADING';
export const USER_LOADED = 'USER_LOADED';
export const AUTH_ERROR = 'AUTH_ERROR';
export const LOGOUT = 'LOGOUT';
export const LOGIN_SUCCESS = 'LOGIN_SUCCESS';
export const LOGIN_FAIL = 'LOGIN_FAIL';
export const CLEAR_PROFILE = 'CLEAR_PROFILE';
export const SET_ERRORS = 'SET_ERRORS';
export const REMOVE_ERRORS = 'REMOVE_ERRORS';
export const GET_EMPLOYEES = 'GET_EMPLOYEES';
export const ADD_EMPLOYEE = 'ADD_EMPLOYEE';
export const DELETE_EMPLOYEE = 'DELETE_EMPLOYEE';
export const HANDLE_OPTION = 'HANDLE_OPTION';
export const SAVE_TIMESHEET = 'SAVE_TIMESHEET';
export const APPROVE_TIMESHEET = 'APPROVE_TIMESHEET';
export const CLOSE_FORM = 'CLOSE_FORM';
export const SET_PERSONAL_DATA = 'SET_PERSONAL_DATA';
export const SHOW_FORM = 'SHOW_FORM';
export const TOGGLE_MODAL = 'TOGGLE_MODAL';
export const SET_ORDERS_LOADING = 'SET_ORDERS_LOADING';
export const GET_ORDERS = 'GET_ORDERS';
export const UPDATE_ORDERS = 'UPDATE_ORDERS';
export const REMOVE_TRAVEL = 'REMOVE_TRAVEL';
export const GET_ACCOMMODATIONS = 'GET_ACCOMMODATIONS';
export const EDIT_ACCOMMODATION = 'EDIT_ACCOMMODATION';
export const REMOVE_ACCOMMODATION = 'REMOVE_ACCOMMODATION';
export const SET_ACC_LOADING = 'SET_ACC_LOADING';
export const SET_BUILDINGS = 'SET_BUILDINGS';
export const CLOSE_MODAL_FORM = 'CLOSE_MODAL_FORM';
export const SHOW_MODAL_FORM = 'SHOW_MODAL_FORM';
