import React, {useState, useEffect, Fragment} from 'react';
import Button from "../UI/Button/Button";
import classes from './TravelForm.module.css';
import _ from "lodash";
import Itinerary from "./Itinerary/Itinerary";
import Spinner from "../UI/Spinner/Spinner";
import {connect} from "react-redux";
import {closeTravelForm, setPersonalData, saveTravel} from "../../reducers/actions/travelsActions";
import DownloadFiles from "../Order/DownloadFiles/DownloadFiles";



const TravelForm = ({travelForm, travelFormLoading, setPersonalData, userType, saveTravel}) => {
    useEffect(()=> changeData(previousPD), [travelForm, travelFormLoading])
    const previousPD = {
        department: travelForm.department,
        position: travelForm.position,
        citizenship: travelForm.citizenship || "н/д",
        DOB: travelForm.DOB || "н/д",
        passport: travelForm.passport || "н/д",
        email: travelForm.email || "н/д",
        bonus: travelForm.bonus || "н/д",
        phone: travelForm.phone || "н/д",
}
    const [employeeData, changeData] = useState("")
    const [travel, setTravel] = useState({
        itineraries: travelForm.itineraries || [{
            departure: "", arrival: "", date: "", type: "Авиа",
            flight: "", timeD: "", timeA: "", notes: ""
        }, {}, {}, {}],
        showHotel: !!travelForm.hotel?.name,
        hotel: travelForm.hotel || {name: "", city: ""},
        status: travelForm.status || "updated",
        numberOfItineraries: travelForm.itineraries ?
            travelForm.itineraries.filter(it => it.departure).length : 1,
    });

    const submitHandler = async (e) => {
        e.preventDefault();
        if ( !_.isEqual(previousPD, employeeData)) {
            setPersonalData(travelForm.empId, employeeData);
        }
        if (travel.itineraries[0].departure) {
            saveTravel(travelForm.empId,
                new Date(travelForm.year, travelForm.month, travelForm.day+1), travel)
        }
    }

    const personalData = <table className={classes.PersonalData}>
        <tbody>
        <tr>
            <td>Заявка на билеты для:</td>
            <td>{travelForm.name}</td>
        </tr>
        <tr>
            <td>Дата рождения</td>
            <td><input type="date" value={employeeData.DOB} onChange={(event) =>
                changeData(prev => ({...prev, DOB: event.target.value}))}/></td>
        </tr>
        <tr>
            <td>Подразделение</td>
            <td>{employeeData.department}</td>
        </tr>
        <tr>
            <td>Должность</td>
            <td>{employeeData.position}</td>
        </tr>
        <tr>
            <td>Гражданство</td>
            <td><input type="text" value={employeeData.citizenship}
                       onChange={(event) =>
                           changeData(prev => ({...prev, citizenship: event.target.value}))}/></td>
        </tr>
        <tr>
            <td>Номер и серия паспорта</td>
            <td><input type="text" placeholder="1104 334567" value={employeeData.passport}
                       onChange={(event) =>
                           changeData(prev => ({...prev, passport: event.target.value}))}/></td>
        </tr>
        <tr>
            <td>E-mail</td>
            <td><input type="email" placeholder="email@gmail.com" value={employeeData.email}
                       pattern="[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,}$"
                       onChange={(event) =>
                           changeData(prev => ({...prev, email: event.target.value}))}/></td>
        </tr>
        <tr>
            <td>Бонусная карта</td>
            <td><input type="text" placeholder="Aeroflot-bonus/Status" value={employeeData.bonus}
                       onChange={(event) =>
                           changeData(prev => ({...prev, bonus: event.target.value}))}/></td>
        </tr>
        <tr>
            <td>Контактный телефон</td>
            <td><input type="tel" placeholder="+7 (900) 123-45-67"
                       pattern="\+7\s?[\(]{0,1}9[0-9]{2}[\)]{0,1}\s?\d{3}[-]{0,1}\d{2}[-]{0,1}\d{2}"
                       value={employeeData.phone}
                       onChange={(event) =>
                           changeData(prev => ({...prev, phone: event.target.value}))}
            />
            </td>
        </tr>
        </tbody>
    </table>
    const changeItineraryHandler = (number, property, value) => {
        const temp = _.cloneDeep(travel.itineraries);
        temp[number - 1][property] = value;
        setTravel(prev => ({
            ...prev,
            itineraries: temp
        }))
    }

    const hotel = <table className={classes.Hotel}>
        <tbody>
        <tr>
            <th>Гостиница</th>
        </tr>
        <tr>
            <td>
                <input style={{width: "210px"}} type="text" placeholder="Название гостиницы"
                       value={travel.hotel.name}
                       onChange={(event) =>
                           setTravel(prev => ({
                                   ...prev, hotel: {
                                       ...prev.hotel,
                                       name: event.target.value
                                   }
                               }
                           ))}
                />
            </td>
            <td>
                <input style={{width: "210px"}} type="text" placeholder="Город"
                       value={travel.hotel.city}
                       onChange={(event) =>
                           setTravel(prev => ({
                                   ...prev, hotel: {
                                       ...prev.hotel,
                                       city: event.target.value
                                   }
                               }
                           ))}
                />
            </td>
        </tr>
        <tr>
            <td>
                <label htmlFor="HotelDateIn">Заезд </label>
                <input type="date" id="HotelDateIn" value={travel.hotel.checkIn}
                       onChange={(event) =>
                           setTravel(prev => ({
                                   ...prev, hotel: {
                                       ...prev.hotel,
                                       checkIn: event.target.value
                                   }
                               }
                           ))}
                />
                <input type="time" value={travel.hotel.checkInT}
                       onChange={(event) =>
                           setTravel(prev => ({
                                   ...prev, hotel: {
                                       ...prev.hotel,
                                       checkInT: event.target.value
                                   }
                               }
                           ))}
                />
            </td>
            <td>
                <label htmlFor="HotelDateOut">Выезд </label>
                <input type="date" id="HotelDateOut" value={travel.hotel.checkOut}
                       onChange={(event) =>
                           setTravel(prev => ({
                                   ...prev, hotel: {
                                       ...prev.hotel,
                                       checkOut: event.target.value
                                   }
                               }
                           ))}
                />
                <input type="time" id="HotelTimeOut" value={travel.hotel.checkOutT}
                       onChange={(event) =>
                           setTravel(prev => ({
                                   ...prev, hotel: {
                                       ...prev.hotel,
                                       checkOutT: event.target.value
                                   }
                               }
                           ))}
                />
            </td>
        </tr>
        <tr>
            <td colSpan={2}>
                <input style={{width: "430px"}} type="text" id="hotelNotes" placeholder="Примечание"
                       value={travel.hotel.notes}
                       onChange={(event) =>
                           setTravel(prev => ({
                                   ...prev, hotel: {
                                       ...prev.hotel,
                                       notes: event.target.value
                                   }
                               }
                           ))}
                />
            </td>
        </tr>
        </tbody>
    </table>

    const options = {
        year: 'numeric',
        month: 'numeric',
        day: 'numeric',
    };
    return (
        <Fragment> {travelFormLoading ? <Spinner /> :
            <form onSubmit={submitHandler} >
                <h3>{new Date(travelForm.year, travelForm.month, travelForm.day+1)
                    .toLocaleString("ru", options) }</h3>
                {travelForm.uploads?.[0] &&
                <DownloadFiles travel_id={travelForm._id} userType={userType}
                               links = {travelForm.uploads} up/>}
                {personalData}
                <Itinerary itin={travel.itineraries} number={1} changeHandler={changeItineraryHandler}/>
                {travel.numberOfItineraries >= 2 && <Itinerary itin={travel.itineraries}
                                                               changeHandler={changeItineraryHandler}
                                                               number={2}/>}
                {travel.numberOfItineraries >= 3 && <Itinerary itin={travel.itineraries}
                                                               changeHandler={changeItineraryHandler}
                                                               number={3}/>}
                {travel.numberOfItineraries >= 4 && <Itinerary itin={travel.itineraries}
                                                               changeHandler={changeItineraryHandler}
                                                               number={4}/>}
                {travel.showHotel && hotel}
                <a className={classes.Anchor} onClick={() => setTravel(prev => ({
                    ...prev,
                    numberOfItineraries: travel.numberOfItineraries + 1
                }))}> ✈</a>
                <a className={classes.Anchor} onClick={() => setTravel(prev => ({
                        ...prev,
                        showHotel: true
                    })
                )}> 🏨 </a>
                {travel.status === "updated" ?
                    <Button type="submit">Отправить</Button> :
                    <span>Заявка уже обработана ({travel.status})</span>}
            </form>}
        </Fragment>
    );
}

export default connect(state => ({travelForm: state.travels.travelForm,
        userType: state.auth.user.type,
    travelFormLoading: state.travels.loading}),
    {setPersonalData, closeTravelForm, saveTravel})(TravelForm)