import React, {useState, Fragment, useEffect} from "react";
import axios from "axios";

const UploadExls = () => {
    // const [description, setDescription] = useState('');
    useEffect(async () => {
        let previousParams;
        previousParams = await axios.get(`/api/services/previousparams`);
        setParsParams(JSON.stringify(previousParams.data));
    }, [])
    const [parsParams, setParsParams] = useState('default');
    const [excel, setExcel] = useState('');
    const [result, setResult] = useState();
    const onSubmit = async (e) => {
        e.preventDefault();
        let formData = new FormData();
        // formData.append('description', description);
        formData.append('parsingParams', parsParams);
        formData.append('excel', excel);
        try {
            const res = await axios.post('/api/auth/excel', formData);
            setResult("Status is: " + res.data)
        } catch (err) {
            console.log(err.message)
            setResult(err.message + " " + err.name)
        }
    }

    return (
        <Fragment>
            <form onSubmit={onSubmit}>
                <label>Параметры<textarea name="parms"
                                          style={{height: "10rem", width: "70%"}}
                                          value={parsParams}
                                          onChange={e => setParsParams(e.target.value)}
                /></label><br/>
                <input
                    type="file"
                    name="excel"
                    onChange={(e) => setExcel(e.target.files[0])}
                /><br/>
                <button type="submit">Загрузить</button>
            </form>
            {result && <p>{result}</p>}
        </Fragment>)
}
export default UploadExls;