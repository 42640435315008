import React, {Fragment} from 'react';
import classes from "./BuildingReport.module.css";
import LodgingStats from "./LodgingStats/LodgingStats";


const abbrrvv = (long) => {
    switch (long) {
    case "Цех добычи нефти и газа":
        return "ЦДНГ"
    case "Цех подготовки и перекачки нефти и газа":
        return "ЦППНиГ"
    case "Испытательная химико-аналитическая лаборатория":
        return "ИХАЛ"
    case "Служба ОТ, ПБ и ООС":
        return "СПБОТООС"
    case "Участок по ТО и ремонту оборудования":
        return "УТОиРО"
    case "Участок по обслуживанию и ремонту энергооборудования":
        return "УОиРЭ"
    case "Участок метрологии, автоматизации, связи и ИТ":
        return "УМАСИТ"
    case "Транспортный участок":
        return "ТУ"
    case "Участок складских операций":
        return "УчСО"
    case "Служба безопасности и режима":
        return "СБ"
    case "Служба бурения и ремонта скважин":
        return "СБиРС"
    case "Отдел обустройства месторождений":
        return "ООМ"
    case "Отдел контроля качества":
        return "ОКК"
    case "Административно-бытовая служба":
        return "АБС"
    case "Центральное инженерно-технологическое управление":
        return "ЦИТУ"
    default:
        return long
    }
}

function BuildingReport({show, setShow, currentBuilding, current, acc, selectDay}) {
    const rooms1t = currentBuilding[0][1];
    const rooms1b = currentBuilding[0][0];
    const rooms2t = currentBuilding[1][1];
    const rooms2b = currentBuilding[1][0];
    const bNumber = currentBuilding[2];
    const cw = `${83}px`
    const whoIsHere = (room) => {
        let result = [['....................................','....................................'],
            ['....................................','....................................']];
        if (room === "___(0)") return result;
        const tenants =acc.filter(acc => acc?.rooms?.[`year${current.year}`]?.[current.month]?.some(r => {
                    const fromDay = r.from === "=>" ? 1 : new Date(r.from).getDate()
                    const toDay = r.to === "=>" ?
                        new Date(current.year, current.month + 1, 0).getDate()
                        : new Date(r.to).getDate();
                    return r.room === bNumber + "-" + room &&
                        selectDay >= fromDay
                        && selectDay <= toDay;
                }
            )).map(t => [t.name.split(" ")[0]+" "+t.name.split(" ")[1]?.[0].toUpperCase()+". "
        + (t.name.split(" ")?.[2]?.[0].toUpperCase() || "")+"." ,
            abbrrvv(t.department)])
        result = [tenants[0] || [], tenants[1] || []];
        return result;
    }
    const floor = (rt, rb, topCoord) => <Fragment>
        <table className={classes.BuildingTable} style={{top: `${topCoord}px`, left: 'calc(50% - 180px)'}}>
            <tbody>
            {rt.map((rt, ind) => <tr> {
                [rt[4] === "2" ? <td>
                    <table style={{borderCollapse: 'collapse'}}>
                        <tr>
                            <td style={{border: 'none', borderBottom: 'grey 1px solid', height: '15px', width: cw}}>
                                {whoIsHere(rt)[0][1] && whoIsHere(rt)[0][1]}
                            </td>
                        </tr>
                        <tr>
                            <td style={{border: 'none', height: '15px', width: cw}}>
                                {whoIsHere(rt)[1][1] && whoIsHere(rt)[1][1]}</td>
                        </tr>
                    </table>
                </td> : <td>{whoIsHere(rt)[0][1] && whoIsHere(rt)[0][1]}</td>,
                    rt[4] === "2" ? <td>
                        <table style={{borderCollapse:'collapse'}}>
                            <tr>
                                <td style={{border: 'none', borderBottom: 'grey 1px solid', height: '15px', width: cw,
                                    background: !whoIsHere(rt)[0][0]?.[0] ? 'lightgreen':'none'}}>
                                    {whoIsHere(rt)[0][0] && whoIsHere(rt)[0][0]}</td>
                            </tr>
                            <tr>
                                <td style={{border: 'none', height: '15px', width: cw,
                                    background: !whoIsHere(rt)[1][0]?.[0] ? 'lightgreen':'none'}}>
                                    {whoIsHere(rt)[1][0] && whoIsHere(rt)[1][0]}</td>
                            </tr>
                        </table>
                    </td> : <td style={{background: !whoIsHere(rt)[0][0]?.[0] ? 'lightgreen':'none'}}>
                        {whoIsHere(rt)[0][0] && whoIsHere(rt)[0][0]}</td>,
                    <td style={{transform: 'rotate(90deg)'}}>{
                        rt === "___(0)" ? '': rt.slice(0,3)}</td>, //Room number
                    <td style={{width: '10px', border: 'none'}}/>, //Aisle
                    <td style={{transform: 'rotate(90deg)'}}>{
                        rb[ind] === "___(0)" ? '': rb[ind].slice(0,3)}</td>,//Room number
                    rb[ind][4] === "2" ? <td>
                        <table style={{borderCollapse:'collapse'}}>
                            <tr>
                                <td style={{border: 'none', borderBottom: 'grey 1px solid', height: '15px', width: cw,
                                    background: !whoIsHere(rb[ind])[0][0]?.[0] ? 'lightgreen':'none'}}>
                                    {whoIsHere(rb[ind])[0][0] && whoIsHere(rb[ind])[0][0]}</td>
                            </tr>
                            <tr>
                                <td style={{border: 'none', height: '15px', width: cw,
                                    background: !whoIsHere(rb[ind])[1][0]?.[0] ? 'lightgreen':'none'}}>
                                    {whoIsHere(rb[ind])[1][0] && whoIsHere(rb[ind])[1][0]}
                                </td>
                            </tr>
                        </table>
                    </td> : <td style={{background: !whoIsHere(rb[ind])[0][0]?.[0] ? 'lightgreen':'none'}}>
                        {whoIsHere(rb[ind])[0][0] && whoIsHere(rb[ind])[0][0]}</td>,
                    rb[ind][4] === "2" ? <td>
                        <table style={{borderCollapse: 'collapse'}}>
                            <tr>
                                <td style={{border: 'none', borderBottom: 'grey 1px solid', height: '15px',width: cw}}>
                                    {whoIsHere(rb[ind])[0][1] && whoIsHere(rb[ind])[0][1]}
                                </td>
                            </tr>
                            <tr>
                                <td style={{border: 'none', height: '15px', width: cw}}>
                                    {whoIsHere(rb[ind])[1][1] && whoIsHere(rb[ind])[1][1]}</td>
                            </tr>
                        </table>
                    </td> : <td>{whoIsHere(rb[ind])[0][1] && whoIsHere(rb[ind])[0][1]}</td> ]}</tr>)
            }
            </tbody>
        </table>
    </Fragment>
    return <Fragment>{show && <Fragment>
        <div className={classes.Backdrop} onClick={() => setShow(false)}/>
        <div className={classes.Report}>
            <h2>План расселения корпус {currentBuilding[2]}</h2>
            <h3 style={{position: "absolute", top: '50px', left: '45%'}}>Этаж 1</h3>
            {floor(rooms1t, rooms1b, -250)}
            <h3 style={{position: "absolute", top: '500px', left: '45%'}}>Этаж 2</h3>
            {floor(rooms2t, rooms2b, 200)}
            <div className={classes.Stats}>
                <LodgingStats bNumber = {bNumber}
                              current = {current}
                acc = {acc}
                selectDay = {selectDay}
                />
            </div>
        </div>
    </Fragment>
    }
    </Fragment>

}

export default BuildingReport;