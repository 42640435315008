import React, {useState} from 'react';
import {accommodationCreateUpdate} from "../../../reducers/actions/accActions";
import {connect} from "react-redux";

function EditComment({acc, close, accommodationCreateUpdate}) {
    const [comment, setComment] = useState(acc.comment);
    const editCommentsSubmit = (e) => {
        e.preventDefault();
        accommodationCreateUpdate({_id: acc._id, name: acc.name, comment})
        close();
    }
    return (
        <form style={{position: "relative", top: "20%", height: "70%"}} onSubmit={editCommentsSubmit}>
            <label>{acc.name} из {acc.department} </label> <br/><br/>
            <textarea name="buildings"
                                      style={{rows: "4", width: "70%"}}
                                      value={comment}
                                      onChange={e => setComment(e.target.value)}
            />
            <div style={{bottom: "-20%", position: "relative"}}>
                <input style={{margin: "10px"}}
                       onClick={close} type="button" value="Отмена"/>
                <input style={{margin: "10px"}}
                       type="submit" value="Внести изменения"
                />
            </div>

        </form>
    );
}

export default connect(null, {accommodationCreateUpdate})(EditComment);