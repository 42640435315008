import React, {Fragment, Suspense, useEffect, useState, memo, lazy} from 'react';
import {useFilters, useSortBy, useTable} from "react-table";
import KhTransport from "./Kharyaga/KhTransport";
import PropTypes from "prop-types";
import {connect} from "react-redux";
import {getAccommodations, vehicleUpdate} from "../../reducers/actions/accActions";
import Spinner from "../../components/UI/Spinner/Spinner";
import {Redirect} from "react-router-dom";
import WeekControls from "./WeekControls";


export const Table = memo(({columns, data}) => {
    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        rows,
        prepareRow
    } = useTable(
        {
            columns,
            data,
            initialState: {sortBy: [{id: 'department'}]}
        },
        useFilters,
        useSortBy,
    )
    return (
        <Fragment>
            <span style={{fontSize: "1rem",color: 'green'}}> ({rows.length} пассажиров)</span>
            <table {...getTableProps()}>
                <thead>
                {headerGroups.map(headerGroup => (
                    <tr {...headerGroup.getHeaderGroupProps()} >
                        {headerGroup.headers.map(column => (
                            <th {...column.getHeaderProps(column.getSortByToggleProps())}>
                                {column.render('Header')}
                                <span>
                                {column.isSorted
                                    ? column.isSortedDesc
                                        ? ' 🔽'
                                        : ' 🔼'
                                    : ''}
                                  </span>
                                <div style={{display: "inline-block"}}>{column.canFilter && column.filter === "includes"
                                    ? column.render('Filter') : null}</div>
                            </th>
                        ))}
                        </tr>
                ))}
                </thead>
                <tbody {...getTableBodyProps()}>
                {rows.map((row, i) => {
                    prepareRow(row)
                    return (
                        <tr {...row.getRowProps()}>
                            {row.cells.map(cell => {
                                return <td {...cell.getCellProps()}>{cell.render('Cell')}</td>
                            })}
                        </tr>
                    )
                })}
                </tbody>
            </table>

        </Fragment>
    )
});

const Transport = ({getAccommodations, loading, acc, vehicleUpdate, userType}) => {
    const SelectColumnFilter = ({column: {filterValue, setFilter, preFilteredRows, id},}) => {
        // Calculate the options for filtering
        // using the preFilteredRows
        const options = React.useMemo(() => {
            const options = new Set()
            preFilteredRows.forEach(row => {
                options.add(row.values[id])
            })
            return [...options.values()]
        }, [id, preFilteredRows])

        // Render a multi-select box
        return (
            <select
                value={filterValue}
                onChange={e => {
                    setFilter(e.target.value || undefined)
                }}
            >
                <option value="">Все даты</option>
                {options.map((option, i) => (
                    <option key={i} value={option}>
                        {option}
                    </option>
                ))}
            </select>
        )
    };
    const Columns = React.useMemo(
        () => [
            {
                Header: "Из Усинска",
                columns: [
                    {
                        Header: 'ФИО',
                        accessor: 'name',
                    },
                    {
                        Header: 'Подразделение',
                        accessor: 'department',
                    },
                    {
                        Header: 'Должность',
                        accessor: 'position',
                    },
                    {
                        Header: 'Выезд',
                        accessor: 'timesheets',
                        Filter: SelectColumnFilter,
                        filter: 'includes',
                    },
                    {
                        Header: 'Транспорт',
                        accessor: 'vehicle',
                    },
                    {
                        Header: 'Водитель',
                        accessor: 'driver',
                    },
                    {
                        Header: 'Время',
                        accessor: 'time',
                    }
                ],
            },
        ],
        []
    );
    const countedDay = Math.floor((new Date() - new Date(new Date().getFullYear(), 0, 0)) /
        (1000 * 60 * 60 * 24))
    const weekCorrection = (year = new Date().getFullYear()) =>
        new Date(year, 0, 7).getDay()
    const [current, setCurrent] = useState({
        week: Math.floor((countedDay + weekCorrection()) / 7),
        year: new Date().getFullYear()
    });
    const firstDayOfTheWeek = (week) => {
        return new Date(current.year, 0, week * 7 - weekCorrection(current.year) + 1, 3)
        //plus one becuause our week starts on Monday
    }
    const lastDayOfTheWeek = (week) =>
        new Date(current.year, 0, (week + 1) * 7 - weekCorrection(current.year), 3);
    useEffect(() => getAccommodations(current.year), [current.year]);
    if (["adFulfill",].includes.userType) return <Redirect to={"/"}/>;

    const MoscowTransfer = lazy(()=> import(`./Moscow/MoscowTransfer`));
    return (
        <Fragment>
            {loading ? <Spinner/> :
                <Fragment>
                    <WeekControls week={current.week} year={current.year}
                                  lastDayOfTheWeek={lastDayOfTheWeek}
                                  setCurrent={setCurrent}/>
                    <KhTransport table={Table} columns={Columns}
                                 acc={acc} current={current}
                                 firstDayOfTheWeek={firstDayOfTheWeek}
                                 lastDayOfTheWeek={lastDayOfTheWeek}
                                 vehicleUpdate={vehicleUpdate}
                                 userType={userType}
                    />
                    <Suspense fallback={<Spinner/>}>
                        <MoscowTransfer current={current}
                                        lastDayOfTheWeek={lastDayOfTheWeek}
                                        table={Table}
                        />
                    </Suspense>

                </Fragment>
            }

        </Fragment>
    );
}
Transport.propTypes = {
    acc: PropTypes.array,
    getAccommodations: PropTypes.func.isRequired,
    userType: PropTypes.string.isRequired,
    loading: PropTypes.bool.isRequired,
    modalForm: PropTypes.object,
    vehicleUpdate: PropTypes.func.isRequired
};

const stateToProps = state => ({
    userType: state.auth.user.type,
    acc: state.accommodations.acc,
    loading: state.accommodations.loading
});

export default connect(stateToProps, {getAccommodations, vehicleUpdate})(Transport);