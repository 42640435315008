import React, {memo} from 'react';
import {Styles} from "./StylesForAccTable";
import {
    useTable, useGlobalFilter,
    useAsyncDebounce, useSortBy, usePagination
} from 'react-table';
import {allDepartments} from "../../utils/allDepartments";

export const addToAndFrom = (acc, current) => (
    acc.filter(a => a?.timesheets && a.timesheets[`year${current.year}`] &&
        a.timesheets[`year${current.year}`][current.month] &&
        a.timesheets[`year${current.year}`][current.month].some(i => i === "ПУ" || i === "10" || i === "11"))
        .map(a => {
                //Заезд
                const from = a.timesheets[`year${current.year}`][current.month].map(
                    (day, ind, month) => (
                        (ind === 0 && ["11", "10"].includes(month[ind])) ||
                        (ind === 0 && day === "ПУ" && !["11", "10"].includes(month[ind + 1]))
                    )
                        ? "=>" :
                        ((day === "ПУ" && !["11", "10"].includes(month[ind - 1])) ||
                            (day === "ПУ" && ["11", "10"].includes(month[ind + 1]))
                        ) ?
                            new Date(current.year, current.month, ind + 1, 3)
                                .toLocaleDateString('ru-RU') : null)
                    .filter(el => el).toString();
                //Выезд
                const to = a.timesheets[`year${current.year}`][current.month].map(
                    (day, ind, month) => ((ind === month.length - 1 && ["11", "10"].includes(month[ind]))
                        || (day === "ПУ" && ind === month.length - 1 && !["11", "10"].includes(month[ind - 1]))
                    ) ? "=>" :
                        (
                            (day === "ПУ" && ["11", "10"].includes(month[ind - 1])) ||
                            (day === "ПУ" && !["11", "10"].includes(month[ind + 1]))
                        ) ?
                            new Date(current.year, current.month, ind + 1, 3)
                                .toLocaleDateString('ru-RU') : null)
                    .filter(el => el).toString();
                return {
                    ...a,
                    to,
                    from
                }
            }
        )
)

function AccTable(props) {
    function GlobalFilter({
                              preGlobalFilteredRows,
                              globalFilter,
                              setGlobalFilter,
                          }) {
        const count = preGlobalFilteredRows.length
        const [value, setValue] = React.useState(globalFilter)
        const onChange = useAsyncDebounce(value => {
            setGlobalFilter(value || undefined)
        }, 200);
        return (<span>Поиск:{' '}
            <input
                value={value || ""}
                onChange={e => {
                    setValue(e.target.value);
                    onChange(e.target.value);
                }}
                placeholder={`Всего ${count} ...`}
                style={{
                    fontSize: '1.1rem',
                    border: '0',
                }}
            /></span>)
    }

    function Table({columns, data}) {
        const {
            getTableProps,
            getTableBodyProps,
            headerGroups,
            page,
            prepareRow,
            visibleColumns,
            preGlobalFilteredRows,
            setGlobalFilter,
            pageOptions,
            pageCount,
            gotoPage,
            nextPage,
            previousPage,
            canNextPage,
            canPreviousPage,
            state: {pageIndex, globalFilter},
        } = useTable(
            {
                columns,
                data,
                initialState: {sortBy: [{id: 'department'}], pageIndex: 0, pageSize: 50}
            },
            useGlobalFilter,
            useSortBy,
            usePagination
        )
        return (
            <React.Fragment>
                <div className="pagination" style={{
                    textAlign: "center",
                    marginBottom: "3px", marginLeft: "10%"
                }}>
                    <button onClick={() => gotoPage(0)} disabled={!canPreviousPage}>
                        {'<<'}
                    </button>
                    {' '}
                    <button onClick={() => previousPage()} disabled={!canPreviousPage}>
                        {'<'}
                    </button>
                    {' '}
                    <button onClick={() => nextPage()} disabled={!canNextPage}>
                        {'>'}
                    </button>
                    {' '}
                    <button onClick={() => gotoPage(pageCount - 1)} disabled={!canNextPage}>
                        {'>>'}
                    </button>
                    {' '}
                    <span>Страница {' '}<strong>
            {pageIndex + 1} из {pageOptions.length}
          </strong>{' '}
        </span>
                </div>
                <table {...getTableProps()}>
                    <thead>
                    {headerGroups.map(headerGroup => (
                        <tr {...headerGroup.getHeaderGroupProps()}>
                            {headerGroup.headers.map(column => (
                                <th {...column.getHeaderProps(column.getSortByToggleProps())}>
                                    {column.render('Header')}
                                    <span>
                                {column.isSorted
                                    ? column.isSortedDesc
                                        ? ' 🔽'
                                        : ' 🔼'
                                    : ''}
                                  </span>
                                </th>
                            ))}
                        </tr>
                    ))}
                    <tr>
                        <th
                            colSpan={visibleColumns.length}
                            style={{
                                textAlign: 'left',
                            }}
                        >
                            <GlobalFilter
                                preGlobalFilteredRows={preGlobalFilteredRows}
                                globalFilter={globalFilter}
                                setGlobalFilter={setGlobalFilter}
                            />
                        </th>
                    </tr>
                    </thead>
                    <tbody {...getTableBodyProps()}>
                    {page.map((row, i) => {
                        prepareRow(row)
                        return (
                            <tr {...row.getRowProps()}>
                                {row.cells.map(cell => {
                                    return <td {...cell.getCellProps()}>{cell.render('Cell')}</td>
                                })}
                            </tr>
                        )
                    })}
                    </tbody>
                </table>

            </React.Fragment>
        )
    }

    const accColumns = React.useMemo(
        () => [
            {
                Header: 'Размещение',
                columns: [
                    {
                        Header: 'Комната',
                        accessor: 'room',
                    }, {
                        Header: 'ФИО',
                        accessor: 'name',
                    }, {
                        Header: 'Подразделение',
                        accessor: 'department',
                    }, {
                        Header: 'Должность',
                        accessor: 'position',
                    }, {
                        Header: 'Заезд',
                        accessor: 'from',
                    }, {
                        Header: 'Выезд',
                        accessor: 'to',
                    }, {
                        Header: 'Комментарий',
                        accessor: 'comment',
                    },
                    {
                        Header: 'Транспорт',
                        accessor: 'vehicle',
                    },
                ],
            },
        ],
        []
    );
    const addVehicle = async (travelDays, a) => {
        if (!window.confirm("Данный пассажир едет транспортом ЗНДХ?")) return;
        const veh = a?.vehicle?.[`year${travelDays[0][0]}`] ? a.vehicle[`year${travelDays[0][0]}`] : [];
        travelDays.forEach(td => {
            veh[td[1]] = [["нет", "нет", "нет"]];
        })
        //todo replace with special route
        // await props.accommodationCreateUpdate({id: a._id, name: a.name, vehicle: veh});
        await props.vehicleUpdate(veh, a._id, props.current.year);
    }
    const getWeek = (to, from) => {
        const joined = to + "," + from;
        const travelDays = [];
        joined.split(",").forEach(too =>{
            if (too !== undefined && too !== "=>") {
                const day = new Date(too.split(".")[2], too.split(".")[1] - 1, too.split(".")[0], 3);
                travelDays.push(day);
            };
        })
        const yearFirstDay = new Date(props.current.year, 0, 1,3);
        return travelDays.map(d=> {
            let year = props.current.year;
            let week = Math.floor(((d - yearFirstDay)/(1000*60*60*24)+yearFirstDay.getDay()-1)/7);
            if (week > 51) {year = year + 1; week = 0;}
            return [year,week]
        })

    }
    return (
        <Styles>
            <Table columns={accColumns} data={addToAndFrom(props.acc, props.current).map(a => (
                {
                    ...a,
                    comment: <div className="Asim" onClick={() =>
                        ["admin", "adAbs"].includes(props.userType) &&
                        props.showModalForm({
                            _id: a._id,
                            comment: a.comment, name: a.name, identificator: "c", department: a.department
                        })}>
                        {a.comment ?
                            (a.comment.length < 10 ? a.comment : a.comment.slice(0, 10) + "...")
                            : "Добавить"}</div>,
                    room: <div className="Asim"
                               onClick={() => ["admin", "adAbs"].includes(props.userType) &&
                                   props.showModalForm({...a, identificator: "r"})}>

                        {a.rooms && a?.rooms[`year${props.current.year}`]?.
                            [props.current.month]?.[a?.rooms[`year${props.current.year}`]?.
                            [props.current.month].length - 1]?.room || "Нет"}
                    </div>,
                    vehicle: allDepartments.includes(a.department) ? "ЗНДХ" : <div className="Asim"
                    onClick={() => addVehicle(getWeek(a.to, a.from), a)}
                    >{(getWeek(a.to, a.from).map(d => a?.vehicle?.[`year${d[0]}`]?.[d[1]]).filter(d=>d)?.[0] && "ЗНДХ")
                    || "Свой" }
                    </div>
                }
            ))

            }/>
        </Styles>
    );
}

export default memo(AccTable);

