import axios from "axios";
import {
    GET_ACCOMMODATIONS,
    EDIT_ACCOMMODATION,
    REMOVE_ACCOMMODATION,
    SET_ACC_LOADING,
    SET_BUILDINGS, CLOSE_MODAL_FORM, SHOW_MODAL_FORM
} from "../actionTypes";
import {setErrors} from "./errorsActions";

export const getAccommodations = (year) => async (dispatch) => {
    dispatch({type: SET_ACC_LOADING});
    try {
        const res1 = await axios.get(`/api/services/buildings`);
        dispatch({type: SET_BUILDINGS, payload: res1.data})
        const res2 = await axios.get(`/api/accommodation/${year}`);
        dispatch({
            type: GET_ACCOMMODATIONS,
            payload: res2.data,
        });
    } catch (err) {
        console.log(err)
        if (err) {
            dispatch(setErrors([err]));
        }
    }
}


export const showModalForm = (content) => async  (dispatch) => {
    dispatch({type: SHOW_MODAL_FORM, payload: content})
}
export const closeModalForm = () => async  (dispatch) => {
    dispatch({type: CLOSE_MODAL_FORM})
}

export const removeAccommodation = (id) => async (dispatch) => {
    try {
        await axios.delete(`/api/accommodation/${id}`);
        dispatch({type: REMOVE_ACCOMMODATION, payload: id});
    } catch (err) {
        if (err) {
            dispatch(setErrors([err]));
        }
    }
}
export const accommodationCreateUpdate = (acc) => async (dispatch) => {
    try {
        const config = {
            headers: {
                'Content-Type': 'application/json',
            },
        };
        const body = JSON.stringify(acc);
        const res = await axios.post(`/api/accommodation/`, body, config);
        dispatch({type: EDIT_ACCOMMODATION, payload: res.data});
        return res.data;
    } catch (err) {
        if (err) {
            dispatch(setErrors([err]));
        }
    }

}
export const roomsUpdate = (rooms, id, year) => async (dispatch) => {
    try {
        const config = {
            headers: {
                'Content-Type': 'application/json',
            },
        };
        const body = JSON.stringify({rooms});
        const res = await axios.put(`/api/accommodation/rooms/${id}/${year}`, body, config);
        dispatch({type: EDIT_ACCOMMODATION, payload: res.data});
    } catch (err) {
        if (err) {
            dispatch(setErrors([err]));
        }
    }
}
export const timesheetsUpdate = (timesheets, id, year) => async (dispatch) => {
    try {
        const config = {
            headers: {
                'Content-Type': 'application/json',
            },
        };
        const body = JSON.stringify({timesheets});
        const res = await axios.put(`/api/accommodation/timesheets/${id}/${year}`, body, config);
        dispatch({type: EDIT_ACCOMMODATION, payload: res.data});
    } catch (err) {
        if (err) {
            dispatch(setErrors([err]));
        }
    }
}
//to check
export const vehicleUpdate = (vehicle, id, year) => async (dispatch) => {
    try {
        const config = {
            headers: {
                'Content-Type': 'application/json',
            },
        };
        const body = JSON.stringify({vehicle});
        const res = await axios.put(`/api/accommodation/vehicle/${id}/${year}`, body, config);
        dispatch({type: EDIT_ACCOMMODATION, payload: res.data});
    } catch (err) {
        if (err) {
            dispatch(setErrors([err]));
        }
    }
}