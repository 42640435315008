import React from 'react';
import classes from "../TravelForm.module.css";

function Itinerary(props) {
    return (
        <table className={classes.Itineraries}>
            <tbody>
            <tr>
                <th>Маршрут № {props.number}</th>
            </tr>
            <tr>
                <td>
                    <input type="text" placeholder="Пункт отправления"
                           value={props.itin[props.number-1].departure}
                           onChange={(event) => {
                               props.changeHandler(props.number, "departure",event.target.value);
                           }}/>
                </td>
                <td>
                    <input type="text" placeholder="Пункт назначения"
                           value={props.itin[props.number-1].arrival}
                           onChange={(event) => {
                               props.changeHandler(props.number, "arrival",event.target.value);
                           }}/>
                </td>
            </tr>
            <tr>
                <td>
                    <input type="date" value={props.itin[props.number-1].date}
                           onChange={(event) => {
                               props.changeHandler(props.number, "date", event.target.value);
                           }}/>
                </td>
                <td>
                    <label htmlFor="types">ЖД/Авиа:</label>
                    <select name="typeOfTransport" defaultValue={props.itin[props.number-1].type}
                            onChange={(event) => {
                        props.changeHandler(props.number, "type", event.target.value);
                    }}>
                        <option value="???">???</option>
                        <option value="Авиа">Авиа</option>
                        <option value="ЖД">ЖД</option>
                        <option value="Трансфер">Т-р</option>
                    </select>
                </td>
            </tr>
            <tr>
                <td>
                    <input type="text" id="flight" placeholder="Рейс"
                           value={props.itin[props.number-1].flight}
                           onChange={(event) => {
                           props.changeHandler(props.number, "flight", event.target.value);
                           }}/>
                </td>
            </tr>
            <tr>
                <td>
                    <label htmlFor="depatrue">Отправление</label>
                    <input type="time"
                           value={props.itin[props.number-1].timeD}
                           onChange={(event) => {
                               props.changeHandler(props.number, "timeD", event.target.value);
                           }}/>
                </td>
                <td>
                    <label htmlFor="arrival">Прибытие</label>
                    <input type="time" id="arrival"
                           value={props.itin[props.number-1].timeA}
                           onChange={(event) => {
                               props.changeHandler(props.number, "timeA", event.target.value);
                           }}/>
                </td>
            </tr>
            <tr>
                <td colSpan={2}>
                    <input style={{width: "430px"}} type="text" id="flightNotes" placeholder="Примечание"
                           value={props.itin[props.number-1].notes}
                           onChange={(event) => {
                               props.changeHandler(props.number, "notes", event.target.value);
                           }}/>
                </td>
            </tr>
            </tbody>
        </table>
    );
}

export default Itinerary;