//["admin", "adCitu", "adAbs", "adApprove", "adFulfill",
//             "cdng", "cppng", "uoire", "utoir", "umasit", "ihal",
//             "tu", "uchso", "sb", "oom", "okk", "sbrs", "pbotos" ]
module.exports = function (type) {
    switch (type) {
        case "cdng":
            return "Цех добычи нефти и газа"
        case "cppng":
            return "Цех подготовки и перекачки нефти и газа"
        case "ihal":
            return "Испытательная химико-аналитическая лаборатория"
        case "pbotos":
            return "Служба ОТ, ПБ и ООС"
        case "utoir":
            return "Участок по ТО и ремонту оборудования"
        case "uoire":
            return "Участок по обслуживанию и ремонту энергооборудования"
        case "umasit":
            return "Участок метрологии, автоматизации, связи и ИТ"
        case "tu":
            return "Транспортный участок"
        case "uchso":
            return "Участок складских операций"
        case "sb":
            return "Служба безопасности и режима"
        case "sbrs":
            return "Служба бурения и ремонта скважин"
        case "oom":
            return "Отдел обустройства месторождений"
        case "okk":
            return "Отдел контроля качества"
        case "adAbs":
            return "Административно-бытовая служба"
        case "adFulfill":
            return "No department"
        default: return null
    }
}