import {
    CLOSE_FORM,
    SHOW_FORM,
    GET_ORDERS, REMOVE_TRAVEL,
    SET_ORDERS_LOADING,
    SET_PERSONAL_DATA,
    SET_TRAVELFORM_LOADING,
    TOGGLE_MODAL,
    UPDATE_ORDERS
} from './actionTypes';

const initialState = {
    loading: true,
    showModal: false,
    ordersLoading: true,
};

function travelReducer(state = initialState, action) {
    const {type, payload} = action;
    switch (type) {
        case SET_TRAVELFORM_LOADING:
            return {
                ...state,
                loading: true,
            }
        case TOGGLE_MODAL:
            return {
                ...state,
                showModal: !state.showModal,
            }
        case CLOSE_FORM:
            return {
                ...state,
                loading: false,
                travelForm: false,
                showModal: false
            }
        case SHOW_FORM:
            return {
                ...state,
                loading: false,
                travelForm: payload
            }
        case SET_PERSONAL_DATA:
            return {
                ...state,
                loading: false,
                travelForm: {...state.travelForm, ...payload}
            }
        case SET_ORDERS_LOADING:
            return {
                ...state,
                ordersLoading: true,
            }
        case GET_ORDERS:
            return {
                ...state,
                ordersLoading: false,
                orders: payload,
            }
        case UPDATE_ORDERS:
            return {
                ...state,
                ordersLoading: false,
                orders: state.orders.map(order => {
                    if (order._id === payload._id) {
                        return {...order, status: payload.status,uploads: payload.uploads}
                    };
                    return order;
                })
            }
        case REMOVE_TRAVEL:
            return {
                ...state,
                ordersLoading: false,
                orders: state.orders.filter(order => order._id !== payload)
            }
        default:
            return state;
    }
}

export default travelReducer;