
module.exports = function (sheet, workhours, year = new Date().getFullYear()) {
    // if (sheet.length < 2) return 0;
    let hours7array =  workhours[`year7hour${year}`];
    let hours8array = workhours[`year8hour${year}`];
    if (!workhours[`year8hour${year}`] || !workhours[`year7hour${year}`]) return 0;
    let female = false;
    if (sheet.flat(1).includes("10")) {female = true}
    let normaArray = !female ? hours8array : hours7array;
    let norma = normaArray.flat(1).filter(el => el !== "_").reduce((a, b) => a + b);
    const fact = sheet.map((month, indM) => month ? month.map((day, indD) => {
        if (day === "11" || day === "11Н" || day === "#11" || day === "#11Н" || day === 11) {
            return 11
        }
        if (day === 10 || day === "10" || day === "10Н" || day === "#10" || day === "#10Н") {
            return 10
        }

        if ( ["ОТ", "К", "Б", "8", 8, "ОВ", "У", "#ОТ", "#К", "#Б", "#8", "#ОВ", "#У"].includes(day) && (normaArray[indM][indD] !== "_"))
             { norma = norma - normaArray[indM][indD]
            return 0
        }
        return 0
    }) : 0)
        .flat(1)
        .reduce((a, b) => a + b);
    return Math.round(fact - norma);
}

