import React, {useState} from 'react';
import PropTypes from 'prop-types';
import classes from './EditRoom.module.css'
import {connect} from "react-redux";
import {closeModalForm, roomsUpdate} from "../../../reducers/actions/accActions";

EditRoom.propTypes = {
    roomsUpdate: PropTypes.func.isRequired,
    acc: PropTypes.object
};

export const convertDate = (date) => `${date.slice(6, 10)}-${date.slice(3, 5)}-${date.slice(0, 2)}`
function EditRoom({acc, closeModalForm, buildings, current, roomsUpdate}) {
    const roomList = Object.values(buildings).map(building => building.flat(2)
        .filter(room => room !== "___(0)" && room.length > 2)
        .map(floor => `${building[2]}-${floor}`)).flat();
    const rooms = acc.rooms?.[`year${current.year}`]?.[current.month]?.length ?
        acc.rooms[`year${current.year}`][current.month] : [{
            room: "Нет",
            from: "",
            to: "",
        }];
    rooms[0].from = acc.from.split(",")[0] === "=>" ?
        new Date(current.year, current.month, 1, 3).toISOString().split('T')[0]
        : convertDate(acc.from.split(",")[0]);
    rooms[rooms.length - 1].to = acc.to.split(",")[acc.to.split(",").length - 1] === "=>" ?
        new Date(current.year, current.month + 1, 0, 3).toISOString().split('T')[0]
        : convertDate(acc.to.split(",")[acc.to.split(",").length - 1]);
    const [edit, setEdit] = useState(rooms);
    const lastDay = !rooms[0].to ? new Date(current.year, current.month + 1, 0)
        .getDate() : new Date(rooms[rooms.length - 1].to).getDate();
    const firstDay = !rooms[0].from ? new Date(current.year, current.month, 1)
        .getDate() : new Date(rooms[0].from).getDate();

    const editRoomSubmit = (e) => {
        e.preventDefault();

        const newRooms = (acc.rooms && acc.rooms[`year${current.year}`]) ?
            acc.rooms[`year${current.year}`] : [];

        newRooms[current.month] = edit;
        roomsUpdate(newRooms, acc._id, current.year);
        closeModalForm();
    }
    const increaseDay = (str) => {
        let day = new Date(str);
        day.setDate(day.getDate() + 1);
        return day.toISOString().split('T')[0]
    }
    return (
        <form className={classes.Form}
              onSubmit={editRoomSubmit}>
            <label>{acc.name} из {acc.department} <br/><br/></label>
            {edit.map((r, i) =>
                <div className={classes.Container} key={i}>
                    <select className={classes.Input}
                            value={r.room} name="newRoom"
                            onChange={e => setEdit(edit.map((cr, ind) =>
                                ind === i ? {...cr, room: e.target.value} : {...cr}
                            ))
                            }>
                        <option value="Нет">Нет</option>
                        {roomList.map(roo => <option value={roo} key={roo}
                        >{roo}</option>)}
                    </select>
                    <input value={i === 0 ? rooms[0].from?.slice(0, 10)
                        : acc.from.split(",").length <= 1 ?
                            increaseDay(edit[i - 1].to)
                            : edit[i].from?.slice(0, 10)}
                           onChange={acc.from.split(",").length > 1 ? (e => setEdit(edit.map((re, ind) => {
                               if (new Date(e.target.value).getMonth() !== current.month) return edit;
                               if (ind === i) re.from = e.target.value;
                               return re
                           }))) : undefined}
                           className={classes.Input} disabled={acc.to.split(",").length <= 1}
                           type="date"/>
                    <input value={r.to?.slice(0, 10)} className={classes.Input}
                           onChange={e => {
                               let mysecretNumber = null;
                               setEdit(edit.map((re, ind) => {
                                       let toObject = re.to;
                                       if (ind === i && (new Date(e.target.value).getDate() > new Date(re.from).getDate() &&
                                           new Date(e.target.value).getDate() <= lastDay
                                           && new Date(e.target.value).getDate() > firstDay
                                       )) {
                                           toObject = e.target.value;
                                           mysecretNumber = ind + 1;
                                       }
                                       return ({
                                           ...re, to: toObject
                                       })
                                   }
                               ));
                               if (mysecretNumber) {
                                   setEdit(edit => {
                                       if (new Date(edit[mysecretNumber - 1].to).getDate() === lastDay) return edit.slice(0, mysecretNumber)
                                       edit[mysecretNumber] = {
                                           ...edit[mysecretNumber - 1],
                                           from: edit[mysecretNumber - 1].to, to: rooms[0].to
                                       };
                                       return edit
                                   })
                               }}}
                           type="date"/>
                </div>
            )}
            <br/>
            <div>
                <input className={classes.Buttons} type="submit" value="Внести изменения"/>
                <input className={classes.Buttons} type="button" value="Без переездов" onClick={() =>
                    setEdit(edit.length > 1 ? edit.splice(-1) : edit)
                }/>
                <input className={classes.Buttons} onClick={closeModalForm} type="button" value="Отмена"/>
            </div>
        </form>
    );
}

const mapStateToProps = state => ({
    acc: state.accommodations.modalForm
})

export default connect(mapStateToProps, {closeModalForm, roomsUpdate})(EditRoom);