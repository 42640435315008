import React, {Fragment, useState} from 'react';
import Button from "../../UI/Button/Button";
import axios from "axios";

function DownLoadExcel() {
    const [status, setStatus] = useState(false)
    const download = async () => {
        try {
        let res = await axios.get(`/api/services/downloadexcel`,
            {responseType: "blob"})
        if (res.status === 200) setStatus(false); else {
            setStatus(res.toString())
        };
        const data = res.data;

        const url = window.URL.createObjectURL(
        new Blob([data], {
            type: res.headers["content-type"]
        })
        );
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", "dump.xlsx");
        document.body.appendChild(link);
        link.click();
        link.parentNode.removeChild(link);
        } catch (err) {
            setStatus(err.toString());
        }
    }
    return (
        <Fragment>
            {(status) && <p>{status}</p>}
            <Button clicked = {() => {download(); setStatus("Подготовка...")}}>
                <strong>
                    EXCEL
                </strong>
            </Button>
        </Fragment>

    );
}

export default (DownLoadExcel);

