import Layout from "./hoc/Layout/Layout";
import {BrowserRouter, Switch, Route, Redirect} from 'react-router-dom';
import React, {useEffect, Fragment} from "react";
import Timesheet from "./containers/timesheet/Timesheet";
import Auth from "./components/UI/Auth/Auth";
import Orders from "./containers/orders/Orders";
import {Provider} from "react-redux";
import store from "./store";
import setAuthToken from "./utils/utils";
import {loadUser} from "./reducers/actions/authActions";
import {LOGOUT} from "./reducers/actionTypes";
import PrivateRoute from "./routing/PrivateRoute";
import Accommodations from "./containers/Accommodations/Accommodations";
import Settings from "./containers/Settings/Settings";
import Transport from "./containers/Transport/Transport";

const App = () => {
    useEffect(() => {
        if (localStorage.token) {
            setAuthToken(localStorage.token);
        }
        store.dispatch(loadUser());
        // log user out from all tabs if they log out in one tab
        window.addEventListener('storage', () => {
            if (!localStorage.token) store.dispatch({type: LOGOUT});
        });
    }, []);
    return (
        <Provider store={store}>
            <BrowserRouter>
                <Fragment>
                    <Layout>
                        <Switch>
                        <Route exact path='/' component={Auth}/>
                        <PrivateRoute exact path="/timesheet" component={Timesheet}/>
                        <PrivateRoute exact path="/travels" component={Orders}/>
                        <PrivateRoute exact path="/accommodations" component={Accommodations}/>
                        <PrivateRoute exact path="/settings" component={Settings}/>
                        <PrivateRoute exact path="/transport" component={Transport}/>
                        <Route exact path="/auth" component={Auth}/>
                        </Switch>
                    </Layout>
                </Fragment>
            </BrowserRouter>
        </Provider>

    );

}

export default App;
