import React from 'react';
import styled from "styled-components";

const LodgingStats = ({acc, bNumber, selectDay, current}) => {
    const TableStyle = styled.div`
      table {
        margin: 0 auto;
        border-collapse: collapse;
        border: black 3px solid;
        width: 30%;
        min-width: 150px;
      }
      
      thead td, tfoot  td {
        border: black 3px solid;
      }
      
      tbody td {
        border: grey 1px solid;
        min-width: 50px;
      }
    `

    const subconSet = [...new Set(acc.map(a => a.department)
        .filter(d => d.search(/\(([^)]+)\)/) >= 0) //
    )];
    const curators = [... new Set(subconSet.map(s => /\(([^)]+)\)/.exec(s)[1]) )];
    const all = acc.filter(acc => acc?.rooms?.[`year${current.year}`]?.[current.month]?.some(r => {
            const fromDay = r.from === "=>" ? 1 : new Date(r.from).getDate()
            const toDay = r.to === "=>" ?
                new Date(current.year, current.month + 1, 0).getDate()
                : new Date(r.to).getDate();
            return selectDay >= fromDay && selectDay <= toDay;
        }
    ))
    const withinBuilding = all.filter(acc => acc?.rooms?.[`year${current.year}`]?.[current.month]?.
        some(r => r.room[0] === bNumber.toString())
    )

    return (
        <TableStyle>
            <table>
                <thead>
                <tr>
                    <td colSpan={3}>ПРОЖИВАЮТ</td>
                </tr>
                <tr>
                    <td> ==== </td>
                    <td>Всего</td>
                    <td>В корпусе</td>
                </tr>
                </thead>
                <tbody>
                <tr>
                    <td>ЗНДХ</td>
                    <td>{all.filter(a=>!subconSet.includes(a.department))?.length}</td>
                    <td>{withinBuilding.filter(a=>!subconSet.includes(a.department))?.length}</td>
                </tr>
                {curators.map(c => <tr>
                    <td>Подрядчики {c}</td>
                    <td>{all.filter(a=> a.department.search(`(${c})`) >= 0)?.length}</td>
                    <td>{withinBuilding.filter(a=> a.department.search(c) >= 0)?.length}</td>
                </tr>)}
                </tbody>
                <tfoot>
                <tr>
                    <td> ИТОГО: </td>
                    <td>{all?.length}</td>
                    <td>{withinBuilding?.length}</td>
                </tr>
                </tfoot>
            </table>
            <br/>
        </TableStyle>
    );
};

LodgingStats.propTypes = {};

export default LodgingStats;