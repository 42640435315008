import {
    CLOSE_MODAL_FORM,
    SHOW_MODAL_FORM,
    GET_ACCOMMODATIONS,
    EDIT_ACCOMMODATION,
    REMOVE_ACCOMMODATION,
    SET_ACC_LOADING,
    SET_BUILDINGS,

} from './actionTypes';

const initialState = {
    loading: true,
    acc: []
};

function accommodationsReducer(state = initialState, action) {
    const {type, payload} = action;
    switch (type) {
        case SET_ACC_LOADING:
            return {
                ...state,
                loading: true
            }
        case SET_BUILDINGS:
            return {
                ...state,
                buildings: payload
            }
        case GET_ACCOMMODATIONS:
            return {
                ...state,
                loading: false,
                acc: payload,
            }
        case EDIT_ACCOMMODATION:
            return {
                ...state,
                acc: [...state.acc.filter(a => a._id !== payload._id), payload],
            }

        case REMOVE_ACCOMMODATION:
            return {
                ...state,
                acc: state.acc.filter(acc => acc._id !== payload)
            }
        case CLOSE_MODAL_FORM:
            return {
                ...state,
                loading: false,
                modalForm: null,
            }
        case SHOW_MODAL_FORM:
            return {
                ...state,
                loading: false,
                modalForm: payload
            }
        default:
            return state;
    }
}

export default accommodationsReducer;