import React, {useState, useEffect, Fragment} from 'react';
import Spinner from "../../components/UI/Spinner/Spinner";
import {connect} from "react-redux";
import PropTypes from "prop-types";
import {
    accommodationCreateUpdate,
    closeModalForm,
    getAccommodations,
    showModalForm, vehicleUpdate
} from "../../reducers/actions/accActions";
import AccGraphic from "../../components/AccGraphic/AccGraphic";
import AccTable from "../../components/AccTable/AccTable";
import Modal from "../../components/UI/Modal/Modal";
import EditRoom from "../../components/AccTable/EditRoom/EditRoom";
import EditComment from "../../components/AccTable/EditComment/EditComment";
import AddAccommodation from "../../components/AddAccommodation/AddAccommodation";
import RemoveAccommodation from "../../components/RemoveAccommodation/RemoveAccommodation";
import {Redirect} from "react-router-dom";


const Accommodations = ({
                            getAccommodations, loading, accommodations, buildings, vehicleUpdate,
                            modalForm, showModalForm, closeModalForm, userType, accommodationCreateUpdate
                        }) => {
    const [current, setCurrent] = useState({
        month: new Date().getMonth(),
        year: new Date().getFullYear()
    });


    const monthChangeHandler = (month) => {
        if (month === -1) {
            month = 11
            setCurrent(prevState => ({...prevState, year: prevState.year - 1}));
        }
        if (month === 12) {
            month = 0
            setCurrent(prevState => ({...prevState, year: prevState.year + 1}));
        }
        setCurrent(prevState => ({...prevState, month: month}));
    };
    const tableAndGraph = (<div>
        <AccGraphic accommodations={accommodations}
                    current={current}
                    buildings = {buildings}
                    monthChangeHandler={monthChangeHandler}
                    userType={userType}
        />
        <AccTable acc={accommodations}
                  buildings={buildings}
                  showModalForm={showModalForm}
                  current={current}
                  vehicleUpdate={vehicleUpdate}
                  userType={userType}
                  accommodationCreateUpdate={accommodationCreateUpdate}
        />
        {["admin", "adAbs"].includes(userType) && <AddAccommodation current={current}/>}
        {["admin", "adAbs"].includes(userType) && <RemoveAccommodation current={current}/>}
        <br/>
    </div>);
    useEffect(() => getAccommodations(current.year), [current.year]);
    if (["adFulfill", ].includes.userType) return <Redirect to={"/"}/>;
    return (
        <Fragment>
            {loading ? <Spinner/> : tableAndGraph}
            <Modal show={modalForm}
                   passDownToBackdrop={closeModalForm}>
                {modalForm?.identificator === "r" ?
                    <EditRoom
                        buildings={buildings}
                        current={current}
                    />
                :
                modalForm?.identificator === "c" ? <EditComment
                    acc={modalForm} close={() => closeModalForm()}/>
                : <Spinner/>
                }
            </Modal>
        </Fragment>
    );
}

Accommodations.propTypes = {
    accommodations: PropTypes.array,
    getAccommodations: PropTypes.func.isRequired,
    userType: PropTypes.string.isRequired,
    loading: PropTypes.bool.isRequired,
    buildings: PropTypes.object,
    modalForm: PropTypes.object
};

export default connect(state => ({
    userType: state.auth.user.type,
    accommodations: state.accommodations.acc,
    loading: state.accommodations.loading,
    buildings: state.accommodations.buildings,
    modalForm: state.accommodations.modalForm
}), {getAccommodations, showModalForm,vehicleUpdate, closeModalForm, accommodationCreateUpdate})(Accommodations);