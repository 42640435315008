import styled from "styled-components";

export const Styles = styled.div`
      padding: 1rem;
      .Asim {
        
      }
      .Asim:hover{
        color: green;
        text-decoration: underline green;
        cursor: pointer;
      }
      table {
        border-spacing: 0;
        border: 1px solid black;
        margin: auto;

        tr {
          :last-child {
            td {
              border-bottom: 0;
            }
          }
          ::marker {
            content: "";
          }
        }
        th,
        td { 
          margin: 0;
          padding: 0.5rem;
          border-bottom: 1px solid black;
          border-right: 1px solid black;

          :last-child {
            border-right: 0;
          }
        }
      }
      @media all and (max-width: 950px)
      {
        table {
          display: block;
          tr {:first-child {
            border-top: none;
          }
            display: list-item; 
            padding: 10px;
            border: none;
            border-top: solid thin #F8C626;
            :last-child {
              td {
                border: solid thin gray;
              }
            }
          }
          th {
            
            :last-child {
            border: solid thin gray;
          }
            display: inline-block;
            border: solid thin gray;
            margin-right: 0.2rem;
            margin-bottom: 3px;
          }
          td {:last-child {
              border: solid thin gray;
            }
             display: inline-block;
             border: solid thin gray;
             margin-right: 0.2rem;
             margin-bottom: 2px;
           }
        }
      } 
    `