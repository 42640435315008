import React, {useState, useEffect} from 'react';
import classes from './TimeCell.module.css';
import CellMenu from "./CellMenu/CellMenu";
import {connect} from "react-redux";
import {optionHandler} from "../../../../reducers/actions/timeSheetActions";

const TimeCell = props => {
    const [menuVisibility, setMenuVisibility] = useState(false)
    const [highlighted, setHighlighted] = useState(false);
    const [visual, setVisual] = useState(props.value);
    const delta = (previous, present) => {
        const a = previous === "_" ? 0 : isNaN(previous) ? 8 : previous;
        const b = present === "_" ? 0 : isNaN(present) ? 8 : present;
        return (a - b)
    }
    useEffect(() => {
        if (props.value.toString().charAt(0) === "#") {
            setHighlighted(true);
            setVisual(props.value.toString().slice(1));
        } else {
            setHighlighted(false);
            setVisual(props.value)
        }
    }, [props.value]);

    const visibilityHandler = () => {
        setMenuVisibility(!menuVisibility);
    }
    const timeCellClasses = [classes.TimeCell, highlighted && classes.Highlighted]
    return (
        <div className={timeCellClasses.join(" ")}
             style={props.value.toString() === "ПУ" ? {backgroundColor: "#abd9c8"} : {}}
             onMouseMove={() => {
                 if (["adApprove"].includes(props.userType)) {return null}
                 if (props.currentCellValue) {
                     props.setOverWork(
                         prev => {
                             return  prev - delta(visual, props.currentCellValue)
                         }
                     )
                     props.optionHandler(props.coord, "#" + props.currentCellValue);
                     setVisual(props.currentCellValue);
                     props.currentCellValue !== visual && setHighlighted(true)
                 }
             }}
             onMouseDown={(event) => props.dragHandler(event)}
             onMouseUp={props.stopDragHandler}
             onClick={visibilityHandler}>
            {visual.toString().charAt(0) === "#" ? visual.toString().slice(1) : visual}
            {menuVisibility && !["adApprove"].includes(props.userType) &&
                                <CellMenu coord={props.coord} value={visual}
                                         onOut={visibilityHandler}
                                         setVisual={setVisual}
                                         setOverWork={props.setOverWork}
                                         setHighlighted={setHighlighted}
                                         delta={delta}
                                         optionHandler={props.optionHandler}/>}
        </div>
    );
};


export default connect(state => ({
    userType: state.auth.user.type,
}), {optionHandler})(TimeCell);