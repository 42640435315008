import React from 'react';
import styles from './TimeControls.module.css';

function TimeControls(props) {
    const months = ['Январь',
        'Февраль',
        'Март',
        'Апрель',
        'Май',
        'Июнь',
        'Июль',
        'Август',
        'Сентябрь',
        'Октябрь',
        'Ноябрь',
        'Декабрь']
    return (
        <div className={styles.ControlArrows}>
          <a onClick={()=>props.changeMonth(props.currentMonth-1)}>⮜</a>
            <select
                value={props.currentMonth}
                onChange={(event => {
                    props.changeMonth(event.target.value*1)
                })}
                id="month"
                name="month"
                size="1"
                style={{fontSize: '1.5rem'}}>
                {months.map((month, index) => <option
                    key={index}
                    value={index} >{month}</option>)}
            </select>
            <a onClick={()=>props.changeMonth(props.currentMonth+1)}>⮞</a>
            <b style={{fontWeight: 900}}>   {props.currentYear}</b>
        </div>
    );
}

export default TimeControls;
