import React from 'react';
import './NavItem.css';
import {NavLink} from 'react-router-dom';

const navItem = (props) => (
    <li className="NavItem">
        <NavLink activeClassName="active"
                 exact={props.exact}
                 to={props.link}>{props.children}</NavLink>
    </li>
)

export default navItem;