import React, {Fragment, useEffect, useRef, useState} from 'react';
import classes from './TimeTable.module.css';
import Employee from "./Employee/Employee";
import Button from "../UI/Button/Button";
import checkHolidays from '../../utils/checkHolidays';

const TimeTable = props => {
    const emptySheet = Array.apply(null, Array(12)).map(month => []);
    const [nameSearch, setNameSearch] = useState('');
    const [daySearch, setDaySearch] = useState(null);
    const daysInMonth = []
    for (let i = 1; i <= props.daysInMonth; i++) {
        daysInMonth.push(i);
    }
    const handleDaySearch = (day) => {
        if (daySearch === day) {
            setDaySearch(null)
        } else {
            setDaySearch(day)
        }
    }
    const departments = [...new Set(props.employees.map(emp => emp.department))];
    const [showMe, setShowMe] = useState(props.collapseDepartments ? [] : [departments[0]])
    const [timesheet, setTimesheet] = useState();
    useEffect(() => {
        setTimesheet(departments.map(dep =>
            <Fragment key={dep}>
                <div className={classes.Department} onClick={() => {
                    // set Department to show or hide
                    if (showMe.includes(dep)) {
                        setShowMe(prevState => prevState.filter(d => d !== dep))
                    } else {
                        setShowMe(prevState => [...prevState, dep])
                    }
                }
                }>{dep}
                    {props.userType !== "adApprove" && <Button style={{
                        position: "relative",
                        left: "5%", top: "0", display: "inline-block", width: "150px", zIndex: 2
                    }}
                                                               clicked={props.saveChanges}>
                        Сохранить в графике</Button>}
                </div>
                <div className={classes[props.pPosition]}>
                    {showMe.includes(dep) && props.employees.filter(emp => emp.department === dep).filter(
                        em => em.name.toLowerCase().search(nameSearch.toLowerCase()) !== -1).filter(em =>
                        daySearch ? ["11", "11Н", "10", "10Н"]
                            .includes(em.timesheet.filter(s => s.year == [props.year])?.[0]?.
                                sheet?.[props.currentM]?.[daySearch - 1].toString()) : true
                    ).map((employee, position) => {
                            if (!employee.timesheet[0] || !employee.timesheet.filter(s => s.year == [props.year])[0]) {
                                return null
                            }
                            if (!employee.timesheet.filter(s => s.year == [props.year])[0].sheet) {
                                employee.timesheet = [...employee.timesheet, {year: props.year, sheet: emptySheet}]
                            }
                            if (!employee.timesheet.filter(s => s.year == [props.year])[0].sheet[props.currentM]) {
                                employee.timesheet.filter(s => s.year == [props.year])[0].sheet[props.currentM] =
                                    daysInMonth.map(days => "_")
                            }

                            return (
                                <Employee key={employee.name} employee={employee} position={position}
                                          year={props.year} currentM={props.currentM} currentYear={props.year}/>
                            )
                        }
                    )}
                </div>
            </Fragment>))
    }, [nameSearch, showMe, daySearch, props.year, props.currentM, props.pPosition])

    //for calendar mode
    const refref = useRef(null);
    if (refref?.current?.children[1]?.style && window.innerWidth<800) {
        let offset = new Date(props.year,props.currentM, 1, 3).getDay() - 1;
        if (offset < 0) offset = 6
        refref.current.children[1].style[`margin-left`] = `${offset*40}px`
    }

    return (
        <div className={classes.TimeTable}>
            <div className={classes.HeadOfTable} ref={refref}>
                <div className={classes.NameSearch}><input type="text"
                                                           onChange={(e) => setNameSearch(e.target.value)}
                                                           placeholder="Поиск по имени"/></div>
                {daysInMonth.map(day => <span
                    onClick={() => handleDaySearch(day)}
                    className={checkHolidays(props.currentM, day) ? classes.Holidays :
                        new Date(props.year, props.currentM, day - 1).getDay() >= 5 ? classes.Weekends : classes.Date
                    } key={day}>{daySearch === day ? "🛠️" : day}</span>)}
                <div style={{width: "65px", display: "inline-block"}}></div>
            </div>
            {/*<div style={{overflow: "hidden"}}> USED TO BE HIDDEN*/}
                <div>
                {timesheet}
            </div>
        </div>
    );
};


export default TimeTable;