import React from 'react';
import Button from "../../UI/Button/Button";
import classes from './ApproveButton.module.css';

function ApproveButton(props) {
    return (
        <div className={classes.ApproveButton}>
            <Button style ={{width: "30px"}} clicked={props.onClick} disabled={props.disabled}>✓</Button>

        </div>
    );
}

export default ApproveButton;