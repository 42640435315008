import React from 'react';
import classes from './CellMenu.module.css';
import {connect} from "react-redux";
import {showTravelForm} from "../../../../../reducers/actions/travelsActions";

const CellMenu = (props) => {
    const options = ["_", 10, 11, "ОТ", "ПУ", "К", "Б", "ОВ", "У", 8, "10Н", "11Н"];

    return (
        <div className={classes.CellMenu} onMouseLeave={props.onOut}>
            {options.map(option => (
                    <div key={option}
                         onClick={() => {
                             if (props.value != option) {
                                 props.setOverWork(
                                     prev => {
                                         return  prev - props.delta(props.value, option)
                                     }
                                 )
                                 props.optionHandler(props.coord, "#" + option);
                                 props.setHighlighted(true);
                                 props.setVisual(option);
                             }
                             props.onOut();
                             {
                                 option === "ПУ" && props.showTravelForm(props.coord)
                             }
                         }}
                    >{option}</div>
                )
            )}
        </div>
    );
}
export default connect(null, { showTravelForm })(CellMenu);