export const allDepartments = [
    "Центральное инженерно-технологическое управление"
    , "Административно-бытовая служба"
    , "Цех добычи нефти и газа"
    , "Цех подготовки и перекачки нефти и газа"
    , "Испытательная химико-аналитическая лаборатория"
    , "Служба ОТ, ПБ и ООС"
    , "Участок по ТО и ремонту оборудования"
    , "Участок по обслуживанию и ремонту энергооборудования"
    , "Участок метрологии, автоматизации, связи и ИТ"
    , "Транспортный участок"
    , "Участок складских операций"
    , "Служба безопасности и режима"
    , "Служба бурения и ремонта скважин"
    , "Отдел обустройства месторождений"
    , "Отдел контроля качества"
    , "Аутстафф"
];