import React, {useState, Fragment} from 'react';
import classes from './Layout.module.css';
import Toolbar from '../../components/UI/Toolbar/Toolbar'
import SideDrawer from '../../components/UI/SideDrawer/SideDrawer';
import store from "../../store";
import {removeErrors} from "../../reducers/actions/errorsActions";
import Modal from "../../components/UI/Modal/Modal";
import {connect} from 'react-redux';

const Layout = ({errorMessages, removeErrors, children}) => {
    const [showSideDrawer, setSideDrawer] = useState(false);
    return <Fragment>
        <Toolbar
            drawerToggleClicked={() => {
                setSideDrawer(!showSideDrawer);
            }}
        />
        <SideDrawer
            open={showSideDrawer}
            closed={() => {
                setSideDrawer(false)
            }}/>
        <Modal show={errorMessages} passDownToBackdrop={() => store.dispatch(removeErrors())}>
            <div style={{
                color: "red",
                textAlign: "center",
                margin: "0 auto",
                marginTop: "50%",
                fontSize: 20
            }}>{errorMessages && errorMessages.join(", ")} </div>
        </Modal>
        <main className={classes.Content}>
            {children}
        </main>
    </Fragment>
};


export default connect(state => (
    {errorMessages: state.errors.messages}
), {removeErrors})(Layout);