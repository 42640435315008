import React from 'react';
import applogo from '../../../assets/logo192.png';
import './Logo.css';

const logo = (props) => (
    <div className="Logo" style={{height: props.height}}>
        <img src={applogo} alt="Shifts_logo"></img>
    </div>
);

export default logo;