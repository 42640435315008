import React, {useState, Fragment} from 'react';
import classes from './Auth.module.css';
import {connect} from 'react-redux';
import PropTypes from 'prop-types';
import {login, logout} from "../../../reducers/actions/authActions";


const Auth = ({login, logout, isAuthenticated, name}) => {
    const [formData, setFormData] = useState({
        email: '',
        password: '',
    });
    const {email, password} = formData;

    const onChange = (e) =>
        setFormData({...formData, [e.target.name]: e.target.value});
    const onSubmit = async (e) => {
        e.preventDefault();
        login(email, password);
    };

    const [error, setError] = useState(null);

    const logMeOut = <div>
        {isAuthenticated ? <h3>Здравствуйте {name}!</h3> : <h2>Для продолжения работы требуется вход</h2>}
        <button className={classes.Button} onClick={logout}>
           ВЫЙТИ
        </button>

    </div>

    const loginform = (<div>
        <h1> ВХОД В СИСТЕМУ</h1>
        {error !== null && <div className={classes.Error}>{error}</div>}
        <form className='form' onSubmit={(e) => onSubmit(e)}>
            <label htmlFor="userEmail">
                e-mail:
            </label>
            <input
                type="email"
                className={classes.Fields}
                name="email"
                placeholder="e.g: aleonidov@nestro.ru"
                value={email}
                onChange={(e) => onChange(e)}
                required
            />
            <label htmlFor="userPassword" className="block">
                Пароль:
            </label>
            <input
                type="password"
                className={classes.Fields}
                name='password'
                placeholder="Your Password"
                minLength='6'
                value={password}
                onChange={(e) => onChange(e)}
            />
            <input type='submit' className={classes.Button} value='ВОЙТИ'/>
        </form>
    </div>)

    return <div className={classes.Auth}>
        {isAuthenticated ? logMeOut : loginform}
    </div>
}

Auth.propTypes = {
    login: PropTypes.func.isRequired,
    isAuthenticated: PropTypes.bool,
};
const mapStateToProps = (state) => ({
    name: state.auth.user?.name,
    isAuthenticated: state.auth.isAuthenticated,
});
export default connect(mapStateToProps, {login, logout})(Auth)

