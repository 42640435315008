import {connect} from "react-redux";
import React, {Fragment, useEffect, useState} from "react";
import axios from "axios";

const Buildings = ({buildings}) => {

    const [buildingsChanged, setBuildings] = useState('default');
    const [result, setResult] = useState();
    useEffect(() => {
        setBuildings(JSON.stringify(buildings));
    }, [])
    const onSubmit = async (e) => {
        e.preventDefault();
        try {
            const res = await axios.patch('/api/services/buildings',
                {buildings: JSON.parse(buildingsChanged)});
            setResult("Status is: " + res.data)
        } catch (err) {
            console.log(err.message)
            setResult(err.message + " " + err.name)
        }
    }
    return(<Fragment>
        <form onSubmit={onSubmit}>
            <label>Параметры<textarea name="buildings"
                                      style={{height: "10rem", width: "70%"}}
                                      value={buildingsChanged}
                                      onChange={e => setBuildings(e.target.value)}
            /></label><br/>
            <button type="submit">Обновить</button>
        </form>
        {result && <p>{result}</p>}
    </Fragment>)
}

export default connect(state => ({
    buildings: state.accommodations.buildings
}))(Buildings)