import React from 'react';
import classes from './AddEmployee.module.css'


function AddEmployee(props) {
    return (
        <div className={classes.AddNewEmployee}>
        <form onSubmit={props.newOne}>
            <label>ФИО:<input id="name" type="text" className={classes.InputFields}/></label>
            <label>Должность:<input id="position" className={classes.InputFields}/></label>
            <label>Подразделение:
                <select className={classes.InputFields}>
                {props.availableDepartments.map(dep =>
                    <option key={dep} value={dep}>{dep}</option>)}
            </select>
            </label>
            <input className={classes.Button} type="submit" value="Добавить"/>
        </form>
        </div>
);
}

export default AddEmployee;