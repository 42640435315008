import {
    SET_ERRORS,
    REMOVE_ERRORS
} from './actionTypes';

const initialState = {
    messages: null
};

function errorReducer(state = initialState, action) {
    const {type, payload} = action;
    switch (type) {
        case SET_ERRORS:
            return {
                ...state,
                messages: payload,
            };
        case REMOVE_ERRORS:
            return {
                ...state,
                messages: null,
            };

        default:
            return state;
    }
}

export default errorReducer;