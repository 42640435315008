import React, {useState} from 'react';
import classes from "./HandleTS.module.css";

function handleTS(props) {
    // eslint-disable-next-line react-hooks/rules-of-hooks
    const [id, setId] = useState(null);

    return (
        <div className={classes.DeleteEmployee}>
            <label>ФИО:<select className={classes.SelectName} onChange={e => {
                setId(e.target.value)
            }} defaultValue="2">
                <option value="2"> -- выбрать работника -- </option>
                {props.employees.map(item => <option key={item._id} value={item._id}>{item.name}</option>)}
            </select></label>
            <button className={classes.ButtonW} onClick={() => props.addTS(props.year, id)}>В график</button>
            <button className={classes.ButtonR} onClick={() => props.deleteTS(props.year, id)}>Удалить</button>
        </div>
    );
}

export default handleTS;