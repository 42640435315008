import React from 'react';
import { Route, Redirect } from 'react-router-dom';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

//Q: what is the ...rest operator? how does it work. A: It is usually used to pass Props!
const PrivateRoute = ({
                          component: Component,
                          auth: { isAuthenticated, loading },
                          ...rest
                      }) => (
    <Route
        {...rest}
        render={(props) =>
            loading ? (
                ""
            ) : isAuthenticated ? (
                <Component {...props} />
            ) : (
                <Redirect to='/auth' />
            )
        }
    />
);
PrivateRoute.propTypes = { auth: PropTypes.object.isRequired };
const mapStateToProps = (state) => ({
    auth: state.auth,
});

export default connect(mapStateToProps)(PrivateRoute);