import axios from "axios";
import {
    CLOSE_FORM,
    SET_TRAVELFORM_LOADING,
    SHOW_FORM,
    SET_PERSONAL_DATA,
    TOGGLE_MODAL,
    GET_ORDERS,
    SET_ORDERS_LOADING,
    UPDATE_ORDERS, REMOVE_TRAVEL
} from "../actionTypes";
import {setErrors} from "./errorsActions";

export const showTravelForm = (coords) => async (dispatch) => {
    try {

        dispatch({type: SET_TRAVELFORM_LOADING});
        const config = {
            headers: {
                'Content-Type': 'application/json',
            },
        };
        const body = JSON.stringify({year: coords.year, month: coords.month, day: coords.day});
        const res1 = await axios.post(`/api/employees/checktravelday/${coords.id}`, body, config);
        if (res1.data.name === false) {
            return
            // dispatch(setErrors(["График ещё не согласован"]))
        } else {
            dispatch({type: TOGGLE_MODAL})
            const res2 = await axios.get(`api/travels/${coords.id}/${coords.year}-${coords.month+1}-${coords.day+1}`)
            if (res2.status) {res1.data.hotel = res2.data.hotel;
                res1.data.itineraries = res2.data.itineraries;
                res1.data.uploads = res2.data.uploads;
                res1.data.status = res2.data.status}
            dispatch({
                type: SHOW_FORM,
                payload: {...res1.data,
                    empId: coords.id,
                    year: coords.year,
                    month: coords.month,
                    day: coords.day},
            });
        }
    } catch (err) {
        // const errors = err.response.data;
        if (err) {
            dispatch(setErrors([err.response.data && err.response.data.msg, err]));
        }
    }

}

export const closeTravelForm = () => ({
    type: CLOSE_FORM
})

export const setPersonalData = (id, newPD) => async (dispatch) => {
    dispatch({type: SET_TRAVELFORM_LOADING});
    try {
        const config = {
            headers: {
                'Content-Type': 'application/json',
            },
        };
        const body = JSON.stringify(newPD);
        const res = await axios.put(`/api/employees/${id}`, body, config);
            dispatch({
                type: SHOW_FORM,
                payload: res.data,
            });
    } catch (err) {
        // const errors = err.response.data;
        if (err) {
            dispatch(setErrors([err]));
        }
    }
    dispatch({type: SET_PERSONAL_DATA,
            payload: newPD})
}
export const saveTravel = (id, date, data) => async (dispatch) => {
    dispatch({type: SET_TRAVELFORM_LOADING});
    try {
        const config = {
            headers: {
                'Content-Type': 'application/json',
            },
        };
        const body = JSON.stringify({id, day: date, itineraries: data.itineraries,
            hotel: data.hotel});
        await axios.post(`/api/travels/`, body, config);

    } catch (err) {
        // const errors = err.response.data;
        if (err) {
            dispatch(setErrors([err]));
        }
    }
    dispatch({type: CLOSE_FORM});
}
export const getTravels = (year, month) => async (dispatch) => {
    dispatch({type: SET_ORDERS_LOADING});
    try {
        const res = await axios.get(`/api/travels/${year}_${month}`);
        dispatch({type: GET_ORDERS, payload: res.data});
    } catch (err) {
        if (err) {
            dispatch(setErrors([err]));
        }
    }
}

export const statusChanger = (travel_id, status) => async (dispatch) => {
    dispatch({type: SET_ORDERS_LOADING});
    try {
        const config = {
            headers: {
                'Content-Type': 'application/json',
            },
        };
        const body = JSON.stringify({status});
        const res = await axios.put(`/api/travels/${travel_id}`, body, config);
        dispatch({type: UPDATE_ORDERS, payload: res.data});
    } catch (err) {
        if (err) {
            dispatch(setErrors([err]));
        }
    }
}
export const removeTravel = (id) => async (dispatch) => {
    dispatch({type: SET_ORDERS_LOADING});
    try {
        const res = await axios.delete(`/api/travels/${id}`);
        dispatch({type: REMOVE_TRAVEL, payload: id});
        dispatch(setErrors([res.data.msg]))
    } catch (err) {
        if (err) {
            dispatch(setErrors([err]));
        }
    }
}
export const fileHandler = (travel_id, filesObject) => async (dispatch) => {
    try {
        const formData = new FormData();
        for (const key of Object.keys(filesObject.uploads)) {
            formData.append('uploads', filesObject.uploads[key])
        }
        const res = await axios.post(`/api/travels/uploads/${travel_id}`, formData);
        dispatch({type: UPDATE_ORDERS, payload: res.data});
    } catch (err) {
        if (err) {
            dispatch(setErrors([err, "Файлы могут быть только pdf формата"]));
        }
    }
}
export const getFile = (filePatch) => async (dispatch) => {
    const fileName = filePatch.split('/')[2];
    try {
        const res = await axios.get(`/api/travels/downloads/d/${fileName}`,{responseType: "blob"});
        const data = res.data; // or res.blob() if using blob responses
        const url = window.URL.createObjectURL(
            new Blob([data], {
                type: res.headers["content-type"]
            })
        );
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", fileName.substring(fileName.indexOf("_")+1,fileName.length));
        document.body.appendChild(link);
        link.click();
        link.parentNode.removeChild(link);

    } catch (err) {
        if (err) {
            dispatch(setErrors([err]));
        }
    }
}
export const removeFile = (link) => async (dispatch) => {
    try {
        const fileName = link.split('/')[2]
        const res = await axios.delete(`/api/travels/uploads/${fileName}`);
        dispatch({type: UPDATE_ORDERS, payload: res.data});
    } catch (err) {
        if (err) {
            dispatch(setErrors([err]));
        }
    }
}