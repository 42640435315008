import React, {useState} from 'react';
import {fileHandler} from "../../../reducers/actions/travelsActions";
import classes from "./UploadFiles.module.css";
import { connect } from "react-redux";

const UploadFiles = ({fileHandler, travel_id}) => {

    const [filesObject, setFilesObject] = useState('');

    const onSubmit = (e) => {
        e.preventDefault();
        fileHandler(travel_id, filesObject);
    }
    return (
        <div className={classes.UploadDiv}>
            <form onSubmit={onSubmit}>
                <div>
                    <input type="file" name="uploads"
                           onChange={(e => setFilesObject({uploads: e.target.files}))} multiple/>
                </div>
                <div>
                    <button type="submit">Отправить билеты</button>
                </div>
            </form>
        </div>
    )
}

export default connect(null, {fileHandler})(UploadFiles);