import React, {useState} from 'react';
import classes from './RemoveAccommodation.module.css'
import {connect} from "react-redux";
import {timesheetsUpdate, removeAccommodation} from "../../reducers/actions/accActions";
import {allDepartments} from "../../utils/allDepartments";



function RemoveAccommodation({removeAccommodation, timesheetsUpdate, acc, current}) {
    const [selectedId, setSelectedId] = useState("Нет")
    const [error, setError] = useState(false);
    const [activeB, setActiveB] = useState(true)

    const removeFully = () => {
        removeAccommodation(selectedId);
        setError(selectedId + " Удален полностью!")
        setTimeout(()=>setError(false), 5000)
    }

    const removeInMonth = async () => {
        setActiveB(false);
        const currentYearTimesheet = acc.find(e => e._id === selectedId)?.timesheets?.[`year${current.year}`];
        if (!currentYearTimesheet) {
            setActiveB(true);
            return
        };
        currentYearTimesheet[current.month] = [];
        //todo remove vehicle when removed timesheet ??

        // for (let i=Math.floor(current.month*4.34); i<Math.ceil((current.month+1)*4.34); i++){
        //     if (updated?.vehicle?.[`year${current.year}`]?.[i]) {
        //         updated.vehicle[`year${current.year}`][i] = [];
        //     }
        // }
        timesheetsUpdate(currentYearTimesheet, selectedId, current.year);
        setTimeout(()=> setActiveB(true), 5000)
    }

    return (
        <div className={classes.Del}>
            <label>ФИО:
                <select className={classes.InputFields} onChange={e => {
                setSelectedId(e.target.value)
            }} defaultValue={null}>
                <option value={null}> -- выбрать проживающего-- </option>
                {acc.filter(a => !allDepartments.includes(a.department))
                    .map(item => <option key={item._id + "ts"} value={item._id}>{item.name}</option>)}
            </select></label>
            <label>Подразделение:
                <input className={classes.InputFields}
                       value={selectedId ? acc.find(o => o._id === selectedId)?.department : "____"}
                       disabled={true}/>
            </label>
            <button className={classes.ButtonW} disabled={!activeB} onClick={removeInMonth}>Из месяца</button>
            <button className={classes.ButtonR} disabled={!activeB} onClick={removeFully}>Полностью</button>
            {error && <span style={{color: "red"}}>{error}</span>}
        </div>
);
}
const mapStateToProps = state => ({
  acc: state.accommodations.acc
});

export default connect(mapStateToProps,
    {removeAccommodation, timesheetsUpdate})(RemoveAccommodation);