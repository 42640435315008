import axios from "axios";
import {
    ADD_EMPLOYEE, APPROVE_TIMESHEET,
    DELETE_EMPLOYEE,
    GET_EMPLOYEES,
    HANDLE_OPTION,
    SAVE_TIMESHEET, SET_LOADING
} from "../actionTypes";
import {setErrors} from "./errorsActions";


export const getTimeSheets = () => async (dispatch) => {

    try {
        const res = await axios.get('/api/employees/');
        const res2 = await axios.get(`/api/services/workhourscalendar/`)
        dispatch({
            type: GET_EMPLOYEES,
            payload: res.data,
            workhours: res2.data
        });

    } catch (err) {
        // const errors = err.response.data;
        if (err) {
            dispatch(setErrors([err]));
        }
    }
}

export const addEmployee = ({name, position, department, year}) => async (dispatch) => {
    const config = {
        headers: {
            'Content-Type': 'application/json',
        },
    };
    const body = JSON.stringify({name, position, department, year});
    try {
        const res = await axios.post('api/employees/create/', body, config);
        dispatch({
            type: ADD_EMPLOYEE,
            payload: res.data,
        });
        window.location.reload();
    } catch (err) {
        if (err) {
            dispatch(setErrors([err]));
        }
    }
}
export const deleteTS = (year, id) => async (dispatch) => {
    try {
        const res1 = await axios.delete(`api/employees/${year}/${id}`);
        dispatch(setErrors([res1.data.msg]));
        const res2 = await axios.get('/api/employees/');
        dispatch({
            type: GET_EMPLOYEES,
            payload: res2.data,
        });
    } catch (err) {
        if (err) {
            dispatch(setErrors([err]));
        }
    }
}
export const addTS = (year, id) => async (dispatch) => {
    try {
        const res1 = await axios.put(`api/employees/${year}/${id}`);
        dispatch(setErrors([res1.data.msg]));
        const res2 = await axios.get('/api/employees/');
        dispatch({
            type: GET_EMPLOYEES,
            payload: res2.data,
        });
    } catch (err) {
        if (err) {
            dispatch(setErrors([err]));
        }
    }
}

export const approveTimesheet = (id) => async (dispatch) => {
    dispatch({type: SET_LOADING});
    try {
        const res = await axios.patch(`api/employees/${id}`);
        dispatch({
            type: APPROVE_TIMESHEET,
            payload: res.data
        });
    } catch (err) {
        if (err) {
            dispatch(setErrors([err]));
        }
    }
}
export const optionHandler = (coord, option) => (dispatch) => {
    dispatch({type: HANDLE_OPTION, payload: {coord, option}})
}

export const saveTimesheet = () => async (dispatch, getState) => {
    let employeesToSave = getState().timeSheets.employees.filter(emp =>
        emp.timesheet.find(o =>
            o.sheet.find(m => m && m.find(day =>
                day.toString().charAt(0) === "#"
                )
            )
        )
    ).map(emp => ({id: emp._id, timesheet: emp.timesheet}));
    dispatch({type: SET_LOADING});
    const config = {
        headers: {
            'Content-Type': 'application/json',
        },
    };
    const body = JSON.stringify({
        employees: employeesToSave
    });
    dispatch({type: SET_LOADING});
    try {
        const res = await axios.patch(`api/employees/`, body, config);
        dispatch({
            type: SAVE_TIMESHEET,
            payload: res.data
        });
    } catch (err) {
        if (err) {
            dispatch(setErrors([err]));
        }
    }
}

export const deleteEmployee = (id) => async (dispatch) => {
    try {
        const res = await axios.delete(`api/employees/${id}`);
        dispatch({
            type: DELETE_EMPLOYEE,
            payload: id
        });
        dispatch(setErrors([res.data.msg]));

    } catch (err) {
        if (err) {
            dispatch(setErrors([err]));
        }
    }
}