import styled from "styled-components";

export const Styles = styled.div`

  .ControlArrows {
    font-size: 2rem;
    color: #339933;
    user-select: none;
    position: -webkit-sticky;
    position: sticky;
    top: 50px;
    background-color: rgb(241, 248, 235);
    z-index: 5;
  }

  .ControlArrows a:hover {
    cursor: pointer;
  }

  padding: 1rem;

  table {
    border-spacing: 0;
    border: 4px solid black;
    box-shadow: 0px 0px 36px 15px rgba(246, 190, 15, 0.28);
    border-radius: 20px;

    margin:  0 auto 30px;
    width: 80%;
    
    tr {
      :last-child {
        td {
          border-bottom: 0;
        }
      }

      ::marker {
        content: "";
      }

      &:nth-of-type(odd) {
        background: #FFFACD;
      }
      &:last-child{
        border-bottom-left-radius: 20px;
        border-bottom-right-radius: 20px;
      }
    }

    thead {
      :first-child{
        border-top-left-radius: 20px;
        border-top-right-radius: 20px;
        //background: none;
      }
       
      
      tr:nth-of-type(odd) {
        color: #339933;
        font-size: 1.5rem;
        border-top-left-radius: 20px;
        border-top-right-radius: 20px;
        
      }
      tr:nth-of-type(2) {
        background: #eeeaea;
      }
      
    }
    th,
    td {
      margin: 0;
      padding: 0.5rem;
      border-bottom: 1px solid black;
      border-right: 1px solid black;

      :last-child {
        border-right: 0;
      }
    }
  }
  
  
  
  
  .SaveDriver {
    display: inline;
  }
  .SaveDriver:hover {
    cursor: pointer;
  }
  input {
    max-width: 80px;
  }
  @media all and (max-width: 950px) {
    table {
      display: block;

      tr {
        :first-child {
          border-top: none;
        }

        display: list-item;
        padding: 10px;
        border: none;
        border-top: solid thin #F8C626;

        :last-child {
          td {
            border: solid thin gray;
          }
        }
      }

      th {
        
        :last-child {
          border: solid thin gray;
        }

        display: inline-block;
        border: solid thin gray;
        margin-right: 0.2rem;
        margin-bottom: 3px;
      }

      td {
        :last-child {
          border: solid thin gray;
        }

        display: inline-block;
        border: solid thin gray;
        margin-right: 0.2rem;
        margin-bottom: 2px;
      }
    }
  }
`