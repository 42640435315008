import NavItem from './NavItem/NavItem';
import './NavigationItems.css'
import { connect } from 'react-redux';

const NavigationItems = ({isAuthenticated}) => {
        return <ul className="NavigationItems">
                {/*<NavItem link="/" exact>Главная</NavItem>*/}
                <NavItem link="/timesheet" exact>График</NavItem>
                <NavItem link="/travels" exact>Заявки</NavItem>
                <NavItem link="/accommodations" exact>Размещение</NavItem>
                <NavItem link="/transport" exact>Транспорт</NavItem>
                <NavItem link="/settings" exact>Настройки</NavItem>
                <NavItem link="/auth" exact>{isAuthenticated ? "Выход" : "Вход"}</NavItem>
            </ul>
}

export default connect(state => ({
        isAuthenticated: state.auth.isAuthenticated
}), null)(NavigationItems);