import React, {useState} from 'react';
import classes from './AddAccommodation.module.css'
import {connect} from "react-redux";
import {accommodationCreateUpdate, timesheetsUpdate} from "../../reducers/actions/accActions";
import {allDepartments} from "../../utils/allDepartments";
import axios from "axios";


const previousYearsCheck = async (name, current, acc) => {
    try {
        let found = acc.find(a => a.name === name && a.timesheets);
        if (!found) {
        const allGuysFromThePast = await axios.get(`/api/accommodation/${current.year - 1}`);
        const allGuysFromTheFuture = await axios.get(`/api/accommodation/${current.year + 1}`);
        found = [allGuysFromThePast, ...allGuysFromTheFuture].find(a => a.name === name && a.timesheets);
        }
        return found;
    } catch (e) {
        console.log(e);
    }
}

function AddAccommodation({accommodationCreateUpdate, timesheetsUpdate, acc, current}) {
    const subcon = [...new Set(acc.filter(a => !allDepartments.includes(a.department)))];
    const byPositions = [...new Set(acc.filter(a => !allDepartments.includes(a.department)))];
    const [error, setError] = useState(false);
    const [activeB, setActiveB] = useState(true);

    const formSubmit = async e => {
        setError(false);
        setActiveB(false)
        e.preventDefault();
        const name = e.target[0].value;
        if (e.target[3].value &&
            (new Date(e.target[3].value).getMonth() !== current.month ||
                new Date(e.target[3].value).getFullYear() !== current.year)
        ) {
            setError("Можно выбрать только текущий месяц");
            setActiveB(true);
            return
        }
        if ((e.target[4].value &&
                (new Date(e.target[4].value).getFullYear() !== current.year)) ||
            (e.target[4].value && e.target[3].value &&
                new Date(e.target[4].value) < new Date(e.target[3].value)
            )
        ) {
            setError("Нужно проверить даты!");
            setActiveB(true);
            return
        }
        const department = !allDepartments.includes(e.target[2].value.trim()) ?
            e.target[2].value.trim() : "Подрядная организация";

        const foundAcc = await previousYearsCheck(name, current, acc);
        let existingAccommodation = foundAcc || await accommodationCreateUpdate({
            name,
            department,
            position: e.target[1].value,
            timesheets: {[`year${current.year}`]: []}
        });


        const updatedTimesheets = existingAccommodation.timesheets[`year${current.year}`] || [];
        const daysInMonth = (month) => new Date(current.year, month + 1, 0).getDate();
        const dateIn = new Date(e.target[3].value);
        const dateOut = new Date(e.target[4].value);
        //Заезд
        const currenMonthTimesheet = updatedTimesheets[current.month] || [];
        const position1 = dateIn.getDate() - 1 || 0;
        currenMonthTimesheet[position1] = dateIn.getDate() ? "ПУ" : "11";
        if (position1 === daysInMonth(current.month) - 1) {
            currenMonthTimesheet[position1 - 1] = "_"
        } else {
            currenMonthTimesheet[position1 + 1] = "11"
        }
        updatedTimesheets[current.month] = currenMonthTimesheet;
        //Выезд
        if (!dateOut || dateOut.getMonth() === dateIn.getMonth()) {
            const position2 = dateOut.getDate() - 1 || daysInMonth(current.month) - 1;
            currenMonthTimesheet[position2] = dateOut.getDate() ? "ПУ" : "11";
            if (position2 === 0) {
                currenMonthTimesheet[position2 + 1] = "_"
            } else {
                currenMonthTimesheet[position2 - 1] = "11"
            }
        } else {
            for (let m = dateIn.getMonth(); m < dateOut.getMonth(); m++) {
                updatedTimesheets[m + 1] = []
                updatedTimesheets[m][daysInMonth(m) - 1] = "11";
                updatedTimesheets[m + 1][0] = "11";
            }
            const position2 = dateOut.getDate() - 1;
            if (updatedTimesheets[dateOut.getMonth()]) {
                updatedTimesheets[dateOut.getMonth()][position2] = "ПУ";
            } else {
                updatedTimesheets[dateOut.getMonth()] = [];
                updatedTimesheets[dateOut.getMonth()][position2] = "ПУ";
            }
            if (position2 === 0) {
                updatedTimesheets[dateOut.getMonth()][position2 + 1] = "_"
            } else {
                updatedTimesheets[dateOut.getMonth()][position2 - 1] = "11"
            }
        }
        await timesheetsUpdate(updatedTimesheets, existingAccommodation._id, current.year);
        setActiveB(true);
    }
    return (
        <div className={classes.AddNewEmployee}>
            <form onSubmit={formSubmit}>
                <label>ФИО:
                    <input id="name" list="names" className={classes.InputFields}
                           autoComplete="off"/>
                    <datalist id="names">
                        {subcon.map(n => <option key={n.name + "ye"} value={n.name}/>)}
                    </datalist>
                </label>
                <label>Должность:
                    <input id="position" list="positions" className={classes.InputFields}
                           autoComplete="off"/>
                    <datalist id="positions">
                        {byPositions
                            .map(p => p.position)
                            .filter((v, ind, arr) => arr.indexOf(v) === ind)
                            .map(p => <option key={p + "-"} value={p}/>)}
                    </datalist>
                </label>
                <label>Подразделение:
                    <input className={classes.InputFields} list="subcontractors" id="subcontractor"
                           autoComplete="off"/>
                    <datalist id="subcontractors">
                        {subcon
                            .map(s=> s.department)
                            .filter((v, ind, arr) => arr.indexOf(v) === ind)
                            .map(s => <option key={s + "dp"} value={s}/>)}
                    </datalist>
                </label>
                <br/>
                <label>Проживание в текущем месяце С :
                    <input type="date"/>
                </label>
                <label> По :
                    <input type="date"/>
                </label>
                <input className={classes.Button} type="submit" value="Внести" disabled={!activeB}/>
                {error && <span style={{color: "red"}}>{error}</span>}
            </form>
        </div>
    );
}

const mapStateToProps = state => ({
    acc: state.accommodations.acc
});

export default connect(mapStateToProps, {accommodationCreateUpdate, timesheetsUpdate})(AddAccommodation);